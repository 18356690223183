import React from 'react';
import styled from 'styled-components';
import { Pnormal, H1 } from '../components/common/typography';

const Section = styled.section`
  /* overflow: hidden; */
  max-width: 640px;
  margin: 16vh auto 0;
  padding: 0 2rem;
`;

const Header = () => {
  return (
    <Section>
      <H1>Case Studies</H1>
      <Pnormal>
        Poniżej przygotowaliśmy prezentację wybranych projektów naszego
        autorstwa. Zobacz jak pracujemy.
      </Pnormal>
    </Section>
  );
};

export default Header;
