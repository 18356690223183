import React from 'react';
import styled from 'styled-components';
import { Plight, Strong } from '../common/typography';
import { Path } from '../../paths';
import { Link } from 'react-router-dom';
import LadnieLogoNew from '../../assets/ladnieLogoNew';

interface FooterProps {
  theme: 'light' | 'dark' | 'homepage';
}

const ColorContainer = styled.div<FooterProps>`
  padding: 5rem 0;
  background: ${(props) =>
    props.theme === 'light' ? 'var(--white)' : 'var(--navy)'};
`;
const HomeFooterContainer = styled.div<FooterProps>`
  height: 8rem;
`;

const RegularFooter = (props: FooterProps) => (
  <ColorContainer theme={props.theme}>
    <Link to={Path.Home}>
      {/* <Logo color='accent' width='80px' firstLoad={false} /> */}
      <LadnieLogoNew color='accent' width='65px' firstLoad={false} />
    </Link>
    <Plight fontSize='1.2rem' style={{ marginBottom: 0 }}>
      Wszelkie prawa zastrzeżone
      <br />
      <Strong color={props.theme === 'light' ? '' : 'var(--white)'}>
        Ładnie &copy; 2020
      </Strong>
    </Plight>
  </ColorContainer>
);

const HomeFooter = (props: FooterProps) => (
  <HomeFooterContainer>
    <Plight fontSize='1.2rem' style={{ marginTop: 0 }}>
      Wszelkie prawa zastrzeżone
      <br />
      <Strong color={props.theme === 'light' ? '' : 'var(--white)'}>
        Ładnie &copy; 2020
      </Strong>
    </Plight>
  </HomeFooterContainer>
);

const Footer = (props: FooterProps) => {
  let footer: JSX.Element | undefined;

  if (props.theme === 'homepage') {
    footer = <HomeFooter theme={props.theme} />;
  } else {
    footer = <RegularFooter theme={props.theme} />;
  }
  return footer;
};

export default Footer;
