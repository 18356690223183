import { useEffect } from 'react';

async function cacheURL(url: string): Promise<void> {
  const cachedURL = await caches.match(url);
  if (!cachedURL) {
    try {
      const v1: Cache = await caches.open('v1');
      await v1.add(url);
    } catch (err) {
      console.error(err);
    }
  }
}

export function useCatch(url: string) {
  useEffect(() => {
    cacheURL(url);
  }, [url]);
}
