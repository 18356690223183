import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Path } from '../paths';
import Navigation from '../components/app/navigation';
import { Button } from '../components/common/button';
import TypedTitle from './components/TypedTitle';
import LadnieLogoNew from '../assets/ladnieLogoNew';

const Section = styled.section`
  min-height: 100vh;
  overflow: hidden;
  background: var(--navy) url('/assets/images/kompMobile.png') center right
    no-repeat;
  padding: 0 5px;
  .typedText {
    white-space: pre;
  }
`;
const MobileHome = (props: {
  articleRef: React.RefObject<HTMLDivElement>;
}): JSX.Element => {
  return (
    <Section>
      <Navigation brandingColor='light' articleRef={props.articleRef} />
      <div style={{ margin: '20vh auto 0', transform: 'rotate(-3deg)' }}>
        <LadnieLogoNew color='light' width='50vw' />
      </div>
      <div
        style={{ margin: '11vh auto 27vh', height: '9rem', padding: '0 2rem' }}
      >
        <TypedTitle />
      </div>
      <Link to={Path.Portfolio}>
        <Button theme='light'> Zobacz Case Study</Button>
      </Link>
    </Section>
  );
};

export default MobileHome;
