import React from "react";

const Puzzle = () => (
  <svg viewBox="0 0 24 24">
    <g>
      <path
        d="M1,24h11h11c0.6,0,1-0.4,1-1V12V1c0-0.6-0.4-1-1-1H12H1C0.4,0,0,0.4,0,1v11v11C0,23.5,0.4,24,1,24z M11.5,18.5
        C11.2,18.7,11,19,11,19.4V22H2v-9h1.2c0,0.1,0,0.2,0,0.3c0,1.8,1.5,3.3,3.3,3.3s3.3-1.5,3.3-3.3c0-0.1,0-0.2,0-0.3H11v2.6
        c0,0.4,0.2,0.7,0.5,0.9c0.3,0.2,0.7,0.1,1-0.1c0.2-0.2,0.5-0.2,0.8-0.2c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
        c-0.3,0-0.5-0.1-0.8-0.2C12.2,18.3,11.8,18.3,11.5,18.5z M22,22h-9v-1.2c0.1,0,0.2,0,0.3,0c1.8,0,3.3-1.5,3.3-3.3
        c0-1.8-1.5-3.3-3.3-3.3c-0.1,0-0.2,0-0.3,0V13h2.6c0.4,0,0.7-0.2,0.9-0.5c0.2-0.3,0.1-0.7-0.1-1c-0.2-0.2-0.2-0.5-0.2-0.8
        c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3c0,0.3-0.1,0.5-0.2,0.8c-0.2,0.3-0.2,0.7-0.1,1c0.2,0.3,0.5,0.5,0.9,0.5H22V22z
         M12.4,5.5C12.8,5.3,13,5,13,4.6V2h9v9h-1.2c0-0.1,0-0.2,0-0.3c0-1.8-1.5-3.3-3.3-3.3c-1.8,0-3.3,1.5-3.3,3.3c0,0.1,0,0.2,0,0.3H13
        V8.4c0-0.4-0.2-0.7-0.5-0.9c-0.3-0.2-0.7-0.1-1,0.1c-0.2,0.2-0.5,0.2-0.8,0.2c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3
        c0.3,0,0.5,0.1,0.8,0.2C11.7,5.6,12.1,5.6,12.4,5.5z M2,2h9v1.2C9.1,3,7.3,4.5,7.3,6.5c0,1.8,1.5,3.3,3.3,3.3c0.1,0,0.2,0,0.3,0V11
        H8.4c-0.4,0-0.7,0.2-0.9,0.5c-0.2,0.3-0.1,0.7,0.1,1c0.2,0.2,0.2,0.5,0.2,0.8c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3
        c0-0.3,0.1-0.5,0.2-0.8c0.2-0.3,0.2-0.7,0.1-1C5.3,11.2,5,11,4.6,11H2V2z"
      />
    </g>
  </svg>
);

const Rwd = () => (
  <svg viewBox="0 0 24 24">
    <g>
      <polygon points="13.3,3.3 10,6.6 8.7,5.3 7.3,6.7 10,9.4 14.7,4.7 	" />
      <path
        d="M22,10h-2V2c0-1.1-0.9-2-2-2H2C0.9,0,0,0.9,0,2v13c0,1.1,0.9,2,2,2h7v2H7v2h6v-2h-2v-2h2v-2H2v-2h5v0h4v0h2v-2
		H2V2h16v8h-2c-1.1,0-2,0.9-2,2v10c0,1.1,0.9,2,2,2h6c1.1,0,2-0.9,2-2V12C24,10.9,23.1,10,22,10z M16,22v-5h2c1.1,0,2-0.9,2-2v-2h-2
		v2h-2v-3h6l0,10H16z"
      />
    </g>
  </svg>
);

const Horse = () => (
  <svg viewBox="0 0 20 23">
    <g>
      <path
        d="M19,21v-1c0-1.3-0.8-2.4-2-2.8V12v-2V7c0-3.9-3.1-7-7-7H8.6L7.1,1.5L5,0.1v3.5l-2,2v1l-3,3v3.2l5.3,1.3L6.4,13
       h0.9l-2,4.1C4,17.4,3,18.6,3,20v1H2v2h18v-2H19z M9.4,2H10c1.8,0,3.4,1,4.3,2.5C13.1,3.6,11.6,3,10,3H9.3L8.8,2.6L9.4,2z M5.6,11
       l-0.9,0.9L2,11.2v-0.8l3-3v-1l2-2V3.9L8.7,5H10c2.8,0,5,2.2,5,5v2v5H7.6l2.3-4.7c0.6-0.6,1.1-1.4,1.1-2.3V8H9v2c0,0.6-0.4,1-1,1H5.6
       z M5,21v-1c0-0.6,0.4-1,1-1h10c0.6,0,1,0.4,1,1v1H5z"
      />
    </g>
  </svg>
);

const Stats = () => (
  <svg viewBox="0 0 22.4 24.4">
    <path
      d="M22,7V0h-7v2h3.6l-3.8,3.8C13.4,3.5,10.9,2,8,2c-4.4,0-8,3.6-8,8s3.6,8,8,8c1.8,0,3.5-0.6,4.9-1.7l0.7,0.7
        l-1,1l6.4,6.4l3.4-3.4L16,14.6l-1,1l-0.7-0.7c1.1-1.4,1.7-3.1,1.7-4.9c0-0.8-0.1-1.5-0.3-2.3L20,3.4V7H22z M19.6,21L19,21.6
        L15.4,18l0.6-0.6L19.6,21z M8,4c2.3,0,4.3,1.3,5.3,3.3l-1.8,1.8L8,5.6l-5.8,5.8C2.1,11,2,10.5,2,10C2,6.7,4.7,4,8,4z M14,10
        c0,3.3-2.7,6-6,6c-2.1,0-3.9-1-5-2.6l5-5l3.5,3.5L14,9.4C14,9.6,14,9.8,14,10z"
    />
  </svg>
);

const Up = () => (
  <svg viewBox="0 0 20 24.3">
    <g>
      <path
        d="M15,18.8c3.1-1.8,5-5,5-8.7c0-5.5-4.5-10-10-10S0,4.6,0,10.1c0,3.6,1.9,6.9,5,8.7v2.3h10V18.8z M13,17.6v1.6
		h-2v-7h1v-2H8v2h1v7H7v-1.6l-0.6-0.3C3.7,15.9,2,13.2,2,10.1c0-4.4,3.6-8,8-8s8,3.6,8,8c0,3.1-1.7,5.8-4.4,7.2L13,17.6z"
      />
      <rect x="5" y="22.1" width="10" height="2" />
      <path d="M4,10.1h2c0-2.2,1.8-4,4-4v-2C6.7,4.1,4,6.8,4,10.1z" />
    </g>
  </svg>
);

const Voice = () => (
  <svg viewBox="0 0 24 24.2">
    <g>
      <path
        d="M12.5,6.6L12,7.1l-0.5-0.5c-0.8-0.8-2.1-0.8-2.9,0c-0.8,0.8-0.8,2.1,0,2.9L9.1,10l0,0l2.9,2.9l2.9-2.9l0,0
		l0.5-0.5c0.8-0.8,0.8-2.1,0-2.9C14.6,5.8,13.3,5.8,12.5,6.6z"
      />
      <path
        d="M21,0H3C1.3,0,0,1.3,0,3v13c0,1.7,1.3,3,3,3h1v5.2L9.9,19H21c1.7,0,3-1.3,3-3V3C24,1.3,22.7,0,21,0z M22,16
		c0,0.6-0.4,1-1,1H9.1L6,19.8V17H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h18c0.6,0,1,0.4,1,1V16z"
      />
    </g>
  </svg>
);

export { Puzzle, Rwd, Horse, Stats, Up, Voice };
