import React, { useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { FirstLoadContext } from './components/app/firstLoad';
import { Path } from './paths';
import GlobalStyle from './globalStyle';
import TagManager from 'react-gtm-module';
// subpages
import Home from './p_home';
import Offer from './p_offer';
import Contact from './p_contact';
import NoMatch from './p_nomatch';
import Portfolio from './p_portfolio';
import Emjot from './p_portfolio/emjot';
import Simba from './p_portfolio/simba';
import Symposio from './p_portfolio/symposio';

const App = (): JSX.Element => {
  let location = useLocation();

  const tagManagerArgs = {
    gtmId: 'GTM-544KKWT',
  };
  TagManager.initialize(tagManagerArgs);

  const [isFirstLoad, setFirstLoad] = useState(true);
  return (
    <div className='App'>
      <GlobalStyle />
      <FirstLoadContext.Provider value={{ isFirstLoad, setFirstLoad }}>
        <TransitionGroup>
          <CSSTransition key={location.key} classNames='fade' timeout={400}>
            <div>
              <Switch location={location}>
                <Route exact path={Path.Home} component={Home} />
                <Route path={Path.Offer} component={Offer} />
                <Route exact path={Path.Portfolio} component={Portfolio} />
                <Route path={Path.Emjot} component={Emjot} />
                <Route path={Path.Simba} component={Simba} />
                <Route path={Path.Symposio} component={Symposio} />
                <Route path={Path.Contact} component={Contact} />
                <Route path='*' component={NoMatch} />
              </Switch>
              {/* <FbCustomerChat /> */}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </FirstLoadContext.Provider>
    </div>
  );
};

export default App;
