import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

/* 
@media only screen and (min-width: 320px) and (max-width: 479px){ ... }

@media only screen and (min-width: 480px) and (max-width: 767px){ ... }

@media only screen and (min-width: 768px) and (max-width: 991px){ ... }

@media only screen and (min-width: 992px){ ... } */


:root{
  font-size: 62.5%;
  --accent: #42eaa2;
  --black: #333743;
  --white: #ffffff;
  --navy: #2b273b;
  --lightGray: #eaeaea;
  --middleGray: #9b9b9b;
  --gray: #7e8c8d;
  --offerIcons: #C3C3C3 ;
  --offerIconFocus: #ADADAD;
  --tagHover: #d6d6d6
  
}


.fade-enter {
  position: absolute;
  z-index: 2;
  width: 100%;
  opacity: 0;
  transition: all 400ms;
}
.fade-enter.fade-enter-active {
  opacity: 1;
}
.fade-exit {
  position: absolute;
  z-index: 1;
  width: 100%;
  opacity: 0;
  transition: all 400ms;
}
.fade-exit.fade-exit-active {
  opacity: 1;
}



`;

export default GlobalStyle;
