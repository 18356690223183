import React from 'react';
import styled from 'styled-components';
import { H1 } from '../components/common/typography';

const Container = styled.div`
  height: 100%;
  background: midnightblue;
`;

const NoMatchHeader = (): JSX.Element => {
  return (
    <Container>
      <H1>404</H1>
    </Container>
  );
};

export default NoMatchHeader;
