import React from "react";
import { Pnormal, H1, H6, Strong } from "../../components/common/typography";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import { Section, TextContainer } from "../../components/common/singleProject";
import { Arrow } from "../../components/common/arrowCircle";
import { Path } from "../../paths";
import styled, { css } from "styled-components";
import { SymposioSygnet } from "../../assets/sygnetSymp";

const Color = styled.div<{ bckgrnd: string }>`
  vertical-align: top;
  margin: 10px;
  .colorTester {
    margin: 50px 0 30px;
    height: 23px;
    width: 100%;
    display: flex;

    .long {
      display: inline-block;
      background: ${(props) => props.bckgrnd};
      height: 100%;
      width: 264px;
    }
    .short {
      display: inline-block;
      background: ${(props) => props.bckgrnd};
      height: 100%;
      width: 23px;
      flex: 0 0 auto;
    }
    ${() => {
      let probki: string[] = [];
      for (let i = 1; i <= 3; i++)
        probki.push(`div:nth-of-type(${i + 1}) {opacity: ${1 - i * 0.25}; }`);
      return css`
        ${probki.join(" ")}
      `;
    }}
    p {
      font-size: 700;
    }
  }
`;
const SymposioSection = styled(Section)`
  margin-bottom: 5rem;
  .symposioLogo {
    width: 100%;
    max-width: 520px;
    margin: 5rem 0;
  }

  .titiliumFont {
    width: 100%;
    max-width: 525px;
    margin: 80px 0 40px;
  }
`;

const Content = () => {
  return (
    <SymposioSection>
      <Arrow link={Path.Portfolio} />

      <TextContainer>
        <H1 margin="0 0 6px">Symposio Centrum Edukacyjne</H1>
        <H6 margin="6px auto 30px">Identyfikacja wizualna</H6>
        <Pnormal>
          W 2013 roku dla centrum edukacyjnego Symposio stworzyliśmy znak
          identyfikacyjny, który po niespełna 10 latach został przez nas
          odświeżony. W ramach aktualizacji stworzyliśmy także dla Klienta
          podstawową identyfikację wizualną.
        </Pnormal>
        <img
          className="symposioLogo"
          src="/assets/images/symposio/logoSympBlack.svg"
          alt="Symposio logo"
          height="100px"
        />
        <Pnormal>
          Sygnet przedstawia książkę, zachowując jednocześnie elementy
          charaktertystyczne dla litery „M”, dzięki czemu został wpisany w
          typografię jako jej bezpośrednia składowa.
          <br />
          <Strong>
            Customowy krój zrealizowaliśmy z zachowaniem konsekwencji, w oparciu
            o kszałt koła.
          </Strong>
        </Pnormal>
        <img
          className="symposioLogo"
          src="/assets/images/symposio/logoSympWhite.svg"
          alt="Symposio logo na kole"
          height="100px"
          style={{ marginBottom: 80 }}
        />
      </TextContainer>
      <Color className="third" bckgrnd="#3b3164">
        <SymposioSygnet bckgrnd="#3b3164" line="#ffffff" />
        <div className="colorTester">
          <div className="long" />
          <div className="short" />
          <div className="short" />
          <div className="short" />
        </div>
        <div>
          <Pnormal color="#3b3164" margin="0 ">
            <Strong>#3b3164</Strong>
          </Pnormal>
          <Pnormal margin="0">
            <Strong>C:84 M:82 Y:40 K:33</Strong>
          </Pnormal>
        </div>
      </Color>
      <Color className="third" bckgrnd="#00a22b">
        <SymposioSygnet bckgrnd="#00a22b" line="#ffffff" />
        <div className="colorTester">
          <div className="long" />
          <div className="short" />
          <div className="short" />
          <div className="short" />
        </div>
        <div>
          <Pnormal color="#00a22b" margin="0 ">
            <Strong>#00a22b</Strong>
          </Pnormal>
          <Pnormal margin="0">
            <Strong>C:83 M:8 Y:100 K:1</Strong>
          </Pnormal>
        </div>
      </Color>
      <Color className="third" bckgrnd="#d3d3d3">
        <SymposioSygnet bckgrnd="#d3d3d3" line="#1a1818" />
        <div className="colorTester">
          <div className="long" />
          <div className="short" />
          <div className="short" />
          <div className="short" />
        </div>
        <div>
          <Pnormal color="#1a1818" margin="0 ">
            <Strong>#d3d3d3</Strong>
          </Pnormal>
          <Pnormal margin="0">
            <Strong>C:84 M:82 Y:40 K:33</Strong>
          </Pnormal>
        </div>
      </Color>
      <div style={{ margin: "4rem 0" }}>
        <TextContainer>
          <Pnormal>
            Pożądane skojarzenia:
            <br />
            <Strong>mądrość, nauka, rozwój, szkoła, sukces, wiedza</Strong>
          </Pnormal>
        </TextContainer>
      </div>
      <img
        data-sizes="auto"
        data-src="/assets/images/symposio/card.jpg"
        data-srcset="
        /assets/images/symposio/card.jpg 510w,
        /assets/images/symposio/card2x.jpg 1020w,
        /assets/images/symposio/card3x.jpg 1530w
    "
        className="lazyload half"
        alt=""
        height="322px"
      />
      <img
        data-sizes="auto"
        data-src="/assets/images/symposio/cardB.jpg"
        data-srcset="
        /assets/images/symposio/cardB.jpg 510w,
        /assets/images/symposio/cardB2x.jpg 1020w,
        /assets/images/symposio/cardB3x.jpg 1530w
    "
        className="lazyload half"
        alt=""
        height="322px"
      />
      <img
        className="titiliumFont"
        src="/assets/images/symposio/font.svg"
        alt="Titilium font wybrany dla Simposio"
        height="100px"
      />
      <div style={{ margin: "40px 0" }}></div>
      {/* full width image */}
      <img
        data-sizes="auto"
        data-src="/assets/images/symposio/papeteria.jpg"
        data-srcset="
        /assets/images/symposio/papeteria.jpg 1040w,
        /assets/images/symposio/papeteria2x.jpg 2040w,
        /assets/images/symposio/papeteria3x.jpg 3120w
    "
        className="lazyload full"
        alt=""
        height="657px"
      />
    </SymposioSection>
  );
};

export default Content;
