import React from 'react';
import styled from 'styled-components';
import { TagFont } from '../components/common/typography';

const Tag = styled.div`
  display: inline-block;
  border: 1px var(--lightGray) solid;
  margin: 0.5rem 1rem;
  padding: 0.3rem 1.5rem;
  border-radius: 1.5rem;
`;

export default (props: { area: string }) => {
  return (
    <Tag className='inne'>
      <TagFont>{props.area}</TagFont>
    </Tag>
  );
};
