import React from 'react';
import styled from 'styled-components';
import { Plight, H3 } from '../components/common/typography';

const Advantage = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 260px;
  vertical-align: top;
  margin: 2vh 40px;

  svg {
    height: 30px;
    stroke: var(--offerIcons);
    fill: var(--offerIcons);
  }

  &:hover {
    svg {
      stroke: var(--gray);
      fill: var(--gray);
    }
  }
`;

export default (props: {
  adjunct: number[];
  title: string;
  text: string;
  Svg?: JSX.Element;
}) => {
  return (
    <Advantage>
      {props.Svg}
      <div className='desc'>
        <H3>{props.title}</H3>
        <Plight>{props.text}</Plight>
      </div>
    </Advantage>
  );
};
