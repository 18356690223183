import React, { useState } from 'react';
import styled from 'styled-components';
import { Path } from '../../paths';
import { Pnormal, H2 } from './typography';
import { Link } from 'react-router-dom';

interface ITile {
  image: JSX.Element;
  title: string;
  text: string;
  link: Path;
}

const Wrapper = styled.div`
  display: inline-block;
  width: 100%;
  margin: 0;
  position: relative;
  img {
    width: 100%;
  }
`;

const Cover = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: RGBA(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 800ms;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`;

export const Tile = (props: ITile) => {
  const [isMJhover, setMJhover] = useState(false);

  return (
    <Link to={props.link} title=''>
      <Wrapper
        onMouseOver={() => setMJhover(true)}
        onMouseOut={() => setMJhover(false)}
      >
        {props.image}
        <Cover isVisible={isMJhover}>
          <div>
            <H2 color='var(--white)'>{props.title}</H2>
            <Pnormal color='var(--accent)'>{props.text}</Pnormal>
          </div>
        </Cover>
      </Wrapper>
    </Link>
  );
};
