import React from 'react';
import styled from 'styled-components';
import { H1, H4, H5, Plight } from '../components/common/typography';
import { Phone, Envelope } from '../assets/contactSvgs';
import { Warsaw } from '../assets/warsaw';
import Footer from '../components/app/footer';

const Section = styled.section`
  min-height: 100vh;
  /* overflow: hidden; */
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow: hidden;
  padding: 16vh 10px 0;
  background: var(--navy);
  .sibling {
    display: inline-block;
    padding: 0 20px;
    vertical-align: top;
    text-align: center;
    @media screen and (min-width: 576px) {
      text-align: left;
    }
  }

  .contactCon {
    display: inline-block;
    padding: 0 20px;
    margin-bottom: 4rem;
    .contact {
      display: inline-block;
      padding: 0 20px;
      text-align: center;
      @media (min-width: 768px) {
        text-align: left;
      }
    }
    svg {
      display: block;
      margin: 0 auto;
      stroke: var(--accent);
      fill: var(--accent);
      width: 24px;

      @media (min-width: 768px) {
        vertical-align: sub;
        display: inline-block;
      }
    }
  }
`;
const WarConR = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
    position: absolute;
    right: -300px;
    top: 40%;
    transform: translateY(-50%);
  }
  svg {
    fill: none;
    stroke: var(--gray);
    width: 666px;
  }
`;

const WarConL = styled.div`
  position: absolute;
  left: -300px;
  top: 60%;
  transform: translateY(-50%);

  svg {
    fill: none;
    stroke: var(--gray);
    width: 666px;
  }
`;

const Header = () => {
  return (
    <Section>
      <WarConL>
        <Warsaw />
      </WarConL>
      <WarConR>
        <Warsaw />
      </WarConR>
      <div></div>
      <div>
        <H1 color='var(--white)' style={{ marginBottom: '8rem' }}>
          Zapytaj o szczegóły współpracy
        </H1>
        <div className='contactCon'>
          <Phone />
          <div className='contact'>
            <H5

            // style={{ textAlign: 'left' }}
            >
              Zadzwoń
            </H5>
            <H4>
              <a href='tel:+48501094584' style={{ color: 'var(--white)' }}>
                501 094 584
              </a>
            </H4>
          </div>
        </div>
        <div className='contactCon'>
          <Envelope />
          <div className='contact'>
            <H5

            // style={{ textAlign: 'left' }}
            >
              Napisz
            </H5>
            <H4>
              <a
                href='mailto:contact@ladnie.agency'
                style={{ color: 'var(--white)' }}
              >
                contact@ladnie.agency
              </a>
            </H4>
          </div>
        </div>
      </div>
      <div>
        <div style={{ display: 'inline-block' }}>
          <Plight color='var(--white)' fontSize='1.6rem' className='sibling'>
            Ładnie by{' '}
            <a
              href='http://tomasztalarek.pl/'
              style={{ textDecoration: 'underline' }}
            >
              Tomasz Talarek
            </a>
          </Plight>
          <Plight className='sibling'>
            <span style={{ whiteSpace: 'nowrap' }}>Warszawice 24A</span>
            <br />
            <span style={{ whiteSpace: 'nowrap' }}>
              08-443 Sobienie Jeziory
            </span>
          </Plight>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Plight className='sibling'>
            <span style={{ whiteSpace: 'nowrap' }}>NIP 5322020979</span>
            <br />
            <span style={{ whiteSpace: 'nowrap' }}>REGON 369872719</span>
          </Plight>
          <Plight
            className='sibling'
            color='var(--gray)'
            style={{ textDecoration: 'underline', fontWeight: 700 }}
          >
            <a href='https://www.instagram.com/ladnie.agency'>Instagram</a>
            <br />
            <a href='https://www.facebook.com/%C5%81adnie-100753374925320'>
              Facebook
            </a>
          </Plight>
        </div>
        <Footer theme='dark' />
      </div>
    </Section>
  );
};

export default Header;
