import React from 'react';
import styled from 'styled-components';
import OfertaSingle from './offerSingle';
import OfertaTag from './offerTag';
import { Puzzle, Rwd, Horse, Stats, Up, Voice } from './../assets/offerSvgs';
import { H2 } from '../components/common/typography';

const Section = styled.section`
  margin: 5rem 0;
  padding: 0 1rem;
  .mainScope {
    max-width: 1020px;
    margin: 5rem auto 0;
  }
`;

const OfferHeader = (): JSX.Element => {
  return (
    <Section>
      <div className='mainScope'>
        <OfertaSingle
          Svg={<Puzzle />}
          title='Design'
          text='Projektujemy identyfikacje wizualne, interfejsy desktopowe i&nbsp;mobilne oraz wszelkiego rodzaju materiały promocyjne - zarówno digitalowe jak i&nbsp;drukowane.'
          adjunct={[2, 4]}
        />
        <OfertaSingle
          Svg={<Rwd />}
          title='Development'
          text='Zapewniamy pełne wsparcie deweloperskie w&nbsp;zakresie front-end i&nbsp;back-end. Od prostych stron internetowych po zaawansowane aplikacje webowe. '
          adjunct={[2, 9]}
        />
        <OfertaSingle
          Svg={<Stats />}
          title='Analiza i audyt'
          text='Zmierzymy dotychczasowe wyniki Twojej witryny i&nbsp;zdefiniujemy potrzeby Twoich klientów. Na podstawie badań zaproponujemy rozwiązania optymalizujące sprzedaż i&nbsp;konwersje.'
          adjunct={[0, 6, 7, 8]}
        />
        <OfertaSingle
          Svg={<Horse />}
          title='Strategia'
          text='Opracujemy strategię komunikacyjną Twojej marki zorientowaną na realizację Twoich celów biznesowych. Przedstawimy plan działania i&nbsp;wesprzemy Cię w&nbsp;jego realizacji.'
          adjunct={[2, 1]}
        />
        <OfertaSingle
          Svg={<Up />}
          title='Koncept'
          text='Na podstawie dostarczonych informacji i&nbsp;pogłębionej analizy rynkowej zbudujemy prototyp Twojego produktu.'
          adjunct={[5, 9]}
        />
        <OfertaSingle
          Svg={<Voice />}
          title='Warsztaty'
          text='W naszej ofercie warsztatowej znajdują się zarówno szkolenia z&nbsp;obsługi dostarczanych przez nas produktów, jak i&nbsp;warsztaty wewnętrzne w zakresie projektowania i&nbsp;realizacji rozwiązań IT.'
          adjunct={[1, 3, 5, 7, 9, 11]}
        />
      </div>
      <H2>Inne</H2>
      <div style={{ maxWidth: 800, margin: '0 auto' }}>
        <OfertaTag area='Research' />
        <OfertaTag area='Branding' />
        <OfertaTag area='Styleguide' />
        <OfertaTag area='Graphics' />
        <OfertaTag area='UI/UX' />
        <OfertaTag area='Mikrointerakcje' />
        <OfertaTag area='Materiały DTP' />
        <OfertaTag area='Angular' />
        <OfertaTag area='React' />
        <OfertaTag area='Vue.js' />
        <OfertaTag area='HTML5' />
        <OfertaTag area='Node.js' />
        <OfertaTag area='MongoDB' />
        <OfertaTag area='SQL' />
        <OfertaTag area='JavaScript' />
        <OfertaTag area='ES6+' />
        <OfertaTag area='PHP' />
        <OfertaTag area='E-Commerce' />
        <OfertaTag area='CMS' />
        <OfertaTag area='CRM' />
        <OfertaTag area='Testing' />
        <OfertaTag area='Integracje' />
        <OfertaTag area='Copywriting' />
        <OfertaTag area='Social Media' />
        <OfertaTag area='Marketing' />
        <OfertaTag area='Testing' />
        <OfertaTag area='Bezpieczeństwo' />
        <OfertaTag area='Optymalizacja' />
      </div>
    </Section>
  );
};

export default OfferHeader;
