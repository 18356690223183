import React, { ReactElement } from 'react';
import styled from 'styled-components';
import PartnerLogo from '../components/common/PartnerLogo';
import { H2 } from '../components/common/typography';

const Section = styled.section`
  margin: 5rem 0;
  padding: 0 1rem;
  .mainScope {
    max-width: 840px;
    margin: 5rem auto 0;
    img {
      margin: 20px;
      filter: grayscale();
    }
  }
`;

export default function Partners(): ReactElement {
  return (
    <Section>
      <div className='mainScope'>
        <H2>Pracowaliśmy z najlepszymi</H2>
        <PartnerLogo alt='Iveco' sourceFiles={['Iveco.svg']} width={92} />
        <PartnerLogo
          alt='Intenson'
          sourceFiles={['Intenson.png', 'Intenson@2x.png', 'Intenson@3x.png']}
          width={127}
        />
        <PartnerLogo
          alt='Sony'
          sourceFiles={['sony.png', 'sony@2x.png', 'sony@3x.png']}
          width={110}
        />
        <PartnerLogo
          alt='Bols'
          sourceFiles={['bols.png', 'bols@2x.png', 'bols@3x.png']}
          width={104}
        />
        <PartnerLogo
          alt='Emmi Mariage'
          sourceFiles={['emmi.png', 'emmi@2x.png', 'emmi@3x.png']}
          width={96}
        />
        <PartnerLogo
          alt='Credit Agricole'
          sourceFiles={[
            'cragricole.png',
            'cragricole@2x.png',
            'cragricole@3x.png',
          ]}
          width={116}
        />
        <PartnerLogo
          alt='Miasto Kobiet'
          sourceFiles={[
            'miastokobiet.png',
            'miastokobiet@2x.png',
            'miastokobiet@3x.png',
          ]}
          width={123}
        />
        <PartnerLogo
          alt='WSIP'
          sourceFiles={['wsip.png', 'wsip@2x.png', 'wsip@3x.png']}
          width={121}
        />
        <PartnerLogo
          alt='Universal'
          sourceFiles={[
            'universal.png',
            'universal@2x.png',
            'universal@3x.png',
          ]}
          width={117}
        />
      </div>
    </Section>
  );
}
