import React, {
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { Keyframes, keyframes } from 'styled-components';
import { Strong, OpeningP } from '../../components/common/typography';

const caret: Keyframes = keyframes`
from, to { border-color: transparent }
  50% { stroke: #42eaa2; }
`;
const Svg = styled.svg`
  animation: ${caret} 0.75s step-end infinite;
`;

export default function TypedTitle({
  fontSize,
}: {
  fontSize?: string;
}): ReactElement {
  const typedTextRef: RefObject<HTMLElement> = useRef(null);
  const [typedText, setTypedText] = useState('');

  useEffect(() => {
    const lines: string[] = [
      `intuicyjne
oprogramowanie`,
      `kreatywne
projekty
graficzne`,
      `użyteczną
architekturę
informacji`,
      `zaawansowane
projekty
drukowane`,
    ];
    let isMounted = true;
    let currentLine: number = 0;
    let i: number = 0;
    let deleting: boolean = false;
    //adds a break when reaches the end of line
    let maxChar: number = lines[currentLine].length + 5;
    const typeEffect = () => {
      return setTimeout(() => {
        //type by one letter
        if (!isMounted) return;

        if (deleting) {
          setTypedText(lines[currentLine].substr(0, i));
          i--;
        } else {
          setTypedText(lines[currentLine].substr(0, i));
          i++;
        }

        //switch typing/deleting and lines
        if (i === 0) {
          deleting = false;
          currentLine = currentLine >= lines.length - 1 ? 0 : currentLine + 1;
          maxChar = lines[currentLine].length + 5;
        } else if (i > maxChar) {
          deleting = true;
        }

        typeEffect();
      }, 100);
    };
    const typing = typeEffect();
    return () => {
      isMounted = false;
      clearTimeout(typing);
    };
  }, []);

  return (
    <OpeningP color='var(--accent)' fontSize={fontSize ? fontSize : '3rem'}>
      Tworzymy <br />
      <Strong className='typedText' ref={typedTextRef} color='var(--white)'>
        {typedText}
      </Strong>
      <Svg viewBox='0 0 3 30' width='3' height='30'>
        <path d='M1 0 V30' />
      </Svg>
    </OpeningP>
  );
}
