import styled from 'styled-components';

const Max1920 = styled.div`
  max-width: 1920px;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
`;

export { Max1920 };
