import React from "react";
import styled from "styled-components";
import { Pnormal, H1, H6, Strong } from "../../components/common/typography";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import { Section, TextContainer } from "../../components/common/singleProject";
import { Arrow } from "../../components/common/arrowCircle";
import { Path } from "../../paths";

const Shout = styled.div`
  vertical-align: top;
  margin: 10px;
  max-width: 250px !important;
  p.number {
    font-family: "Fira Sans", sans-serif;
    font-size: 4.5rem;
    line-height: 5.4rem;
  }
  p.subline {
    font-family: "Merriweather", serif;
    font-size: 1.6rem;
    line-height: 2.5rem;
    font-weight: 700;
    color: var(--gray);
  }
`;

const SimbaSection = styled(Section)`
  margin-bottom: 5rem;
  .logoSimba {
    display: block;
    width: 300px;
    margin: 70px auto 80px;
  }
  .referencje {
    text-decoration: underline;
  }
`;

const Content = () => {
  return (
    <SimbaSection>
      <Arrow link={Path.Portfolio} />
      <TextContainer>
        <H1 margin="0 0 6px">Simba Toys</H1>
        <H6 margin="6px auto 30px">Katalogi produktowe</H6>
        <Pnormal>
          Dla firmy <Strong>Simba Toys</Strong> cyklicznie realizujemy katalogi
          drukowane z produktami dla najmłodszych. Dotychczasowy zakres prac
          obejmował przygotowanie trzech katalogów bazując na ofercie z
          poprzednich lat.
        </Pnormal>
      </TextContainer>
      <Shout className="third">
        <p className="number">400+</p>
        <p className="subline">
          Stron wypełnionych
          <br />
          treścią
        </p>
      </Shout>
      <Shout className="third">
        <p className="number">4</p>
        <p className="subline">
          Katalogi <br />
          produtkowo&#8209;reklamowe
        </p>
      </Shout>
      <Shout className="third">
        <p className="number">35 dni</p>
        <p className="subline">
          Średni czas <br />
          wykonania katalogu
        </p>
      </Shout>
      {/* full width stack */}
      <img
        data-sizes="auto"
        data-src="/assets/images/simba/stack.jpg"
        data-srcset="
        /assets/images/simba/stack.jpg 1040w,
        /assets/images/simba/stack2x.jpg 2080w,
        /assets/images/simba/stack3x.jpg 3120w
    "
        className="lazyload full"
        alt="Okladki magazynu simba"
        height="657px"
        style={{ marginTop: 50 }}
      />
      {/* full width logo */}
      <img
        data-sizes="auto"
        data-src="/assets/images/simba/simbaLogo.png"
        data-srcset="
        /assets/images/simba/simbaLogo.png 300w,
        /assets/images/simba/simbaLogo2x.png 600w,
        /assets/images/simba/simbaLogo3x.png 900w
    "
        className="lazyload logoSimba"
        alt="logo simga"
        height="300px"
      />
      {/* two images per row */}
      <img
        data-sizes="auto"
        data-src="/assets/images/simba/yellowCatalog.jpg"
        data-srcset="
        /assets/images/simba/yellowCatalog.jpg 510w,
        /assets/images/simba/yellowCatalog2x.jpg 1020w,
        /assets/images/simba/yellowCatalog3x.jpg 1530w
    "
        className="lazyload half"
        alt=""
        height="322px"
      />
      <img
        data-sizes="auto"
        data-src="/assets/images/simba/pinkCatalog.jpg"
        data-srcset="
        /assets/images/simba/pinkCatalog.jpg 510w,
        /assets/images/simba/pinkCatalog2x.jpg 1020w,
        /assets/images/simba/pinkCatalog3x.jpg 1530w
    "
        className="lazyload half"
        alt=""
        height="322px"
      />

      {/* two images per row */}

      <img
        data-sizes="auto"
        data-src="/assets/images/simba/blueCatalog.jpg"
        data-srcset="
        /assets/images/simba/blueCatalog.jpg 510w,
        /assets/images/simba/blueCatalog2x.jpg 1020w,
        /assets/images/simba/blueCatalog3x.jpg 1530w
    "
        className="lazyload half"
        alt=""
        height="322px"
      />
      <img
        data-sizes="auto"
        data-src="/assets/images/simba/acatalog.jpg"
        data-srcset="
        /assets/images/simba/acatalog.jpg 510w,
        /assets/images/simba/acatalog2x.jpg 1020w,
        /assets/images/simba/acatalog3x.jpg 1530w
    "
        className="lazyload half"
        alt=""
        height="322px"
      />
      <TextContainer>
        <Pnormal>
          Sprawnie realizujemy skomplikowane projekty wielostronnicowe. <br />
          Sprawdź{" "}
          <a
            className="referencje"
            target="_blank"
            href="
            /assets/referencjeLadnie.pdf"
          >
            nasze referencje
          </a>{" "}
          od firmy Simba Toys.
        </Pnormal>
      </TextContainer>
    </SimbaSection>
  );
};

export default Content;
