import React from 'react';
import styled, { keyframes } from 'styled-components';

const mapAnim = keyframes`
from{  
	stroke-dasharray: 4500;
  stroke-dashoffset: 4500;}
to {
	stroke-dasharray: 4500;
  stroke-dashoffset: 0;
}
`;

const Svg = styled.svg`
  animation: ${mapAnim} 2000ms;
`;

const Warsaw = () => (
  <Svg
    viewBox='0 0 979.8 1026.1'

    //  style="enable-background:new 0 0 979.8 1026.1;" xml:space="preserve"
  >
    {/* <sodipodi:namedview  bordercolor="#666666" borderopacity="1.0" gridtolerance="10.0" guidetolerance="10.0" id="base" inkscape:current-layer="svg2" inkscape:cx="514.20931" inkscape:cy="1360.4319" inkscape:pageopacity="0.0" inkscape:pageshadow="2" inkscape:window-height="614" inkscape:window-width="999" inkscape:window-x="18" inkscape:window-y="-14" inkscape:zoom="0.58327458" objecttolerance="10.0" pagecolor="#ffffff">
	</sodipodi:namedview> */}
    <g id='layer2' transform='translate(30.7708,-317.1503)'>
      <path
        id='first'
        d='M134.2,485.9l-20.4,7.5l-6.5-8.8l-3.5,5.6l-4.1,1.8l-1.1-4.4c-8.1,3.3-16.9,5-25.9,6.2
		c-12.5,14.4-12.3,22.2-14.3,31c-10.2,0.1-21.9-0.5-30.4-4.8c-1.4-0.6-2.8,0.9-2.3,1.5c3.9,5.1,11.4,15.2,10.9,18.9
		c-0.4,3.3-9.9,39.1-9.9,39.1l1.9,10l-2.2,61.1c3.9,0.6,8,0.4,12.5,2.8l-6.4,56.2C21.4,728.1,10.3,746.7-2.1,765l68.2,12.7
		c4.6,15.3-1.9,35.9-7.4,56.1L36.7,834c-6.8,6.4-12.8,10.2-18.1,11.6v13.6l5.5,2.5l-1.8,30.1l-3.6,0.2l-5.2,27.4l-0.8,2.4l-22.8,5
		c-14.8,5.2-20.6,14.3-20.6,25.8c-1.3,1.8,8.4,34.3,10.5,33.6c1.7,0.9,1,15.9,0.3,16l24.4-10.6l0.2,30.9l15.7-0.4
		c1.3,1,2.7,1.5,4.5,0l52.2-0.2l2.9-5.6l14.8-0.6l7.2-0.8l0.8-4.1l2.5-2.1l2.3,1.9l9.3-2.1l-8.7,24.5l12.6,4.3l0.4,3.1l3.7,0.6
		l1.6,2.3l0.2,23.1h4.9l9.1,14.4l-2.7,3.9l14.4,7.8l-0.6,3.1c7.5,7.1,18.1,14.3,31,18.5c4,1.3,7.5,2.2,11.5,5.8l13.3,2.9l0.2,12.2
		c14.5,13.4,27.6,26.9,50.4,32.2c8.1,5.1,15.8,11.4,22.5,21l-12.2,24c0,0,0.1,15.1-0.6,17.9c2.3,1.5,8,14.1,7.6,15.3l14,0.9
		c-1.6,2.9-3,4.8-5,6.7c-1.8,2.2-3.3,3.5-3.7,6.6l5.1,15.9c-2.5,5.2-4.4,8.6-6.8,12.8c-1.4,2.4-1.4,4.6-0.1,5.5l5.3,2.2
		c0,0-4.5,15.2-5.1,17.8c-0.5,1.1,6.6,2.6,6.6,2.6l-0.9,4.7c5.8,1.5,11,2.7,17.3,1.3l56.9-0.9l0.4-8.8l39.8-0.8l-1.2-2.2l15.4-16.6
		l0.7-3.3l4.3-3.2l10.5,8.6l0.6,1.9l8.6,6.2l9.9,2.7l48.8,34.4c0,0,3.5,3.7,8,2.4c2.3-0.7,5.8-3.9,7.6-6.8c3.3-5.3,4-9.4,6.6-11.7
		c1.3-1.1,8.2-6.8,17.4-6.2c6.6,0.4,13.3-2,17.1-4.1l4.5-0.5l-5.3-8.8l-2.5-1l22.9-18.5l-2.6-5.2l17.2-15.9
		c13.8,2.5,20.6-9.5,24.8-26.7l6.4-5.2c0,0,1.8,4.7,3.9,7.5c2.3,3.1,2.2,5.7,2.2,5.7c2.2,3.9,5.6,1.8,6-0.2
		c0.5-14.1,5.6-23.5,13.1-30.2l-0.4-4.5c3.9-2,8.2-4.3,11.2-8l5.5-10.8l7.5-7.8c2.7-0.1,4.4,0.5,7.5-0.7c0.2-1.8,0.6-3.4-0.9-8.7
		c1.1-1.4,2.3-1.9,3.3-4.7c7.7-0.3,14.2-2,21.3-4c9.7,0.9,18.8-2.5,27.6-7.1l0.9-7.3l28.3-8.2l1.5,3.6l62.4-9
		c14.9-11.8,29.9-19.1,44.8-26.6l32.6,22.1l-2.4,5.4l19.1,16.3l16.3-21.9l-19.5-16.3l5.8-12.2l6.9-2.1l3.2-12.7
		c1.2-4.3-0.6-9.5,6.9-11.8l7.5-0.6l0.9-2.4l6.8-0.6c0.8-4.2-2.7-5.7-7.5-6.2l-0.7-12.3l6.2,0.4l0.3,4.7l2-0.1l0.2-8.4l-3.6-0.2
		l-22.6-30.9c6-3.1,10.5-8,13.1-13.7l-59.1-37.4l5.6-7.5l0.3-5.2c2.9-1.8,5.8-3.7,8.4-6.9l10.9-5.1c-1-2-2.5-4.1-6.4-6.9l-0.4-8.6
		c4.8-4.4,9.7-8.7,17.3-12.2c5-0.2,10.4,0.1,14.6-1.3c-13-22.4-19.6-32.1-27.1-47.8l-2.3,0l2.4-35.4c4-4.8,7.7-9.7,9.4-14.8
		l0.4-10.3h-5.1l-7,7.6c-8.2,1.8-16.2,2.4-23.7,0.5l-3-33.3l36.7-2.4l1.9-29l-4.3,0.2l-5.4-35.4l-139.6,25.7l-25.3-3.5
		c7.4-1.3,8.5-6.4,10.9-10.6l0.6-27.2c-2.8-4.2-5.5-9.9-8.7-9.5c-3,0.3-16.1-18.4-24.1-27.6l1.7-6.8l1.7-40.2l-7.3-8.4l-10.1,4.2
		l-8.6,18.8l-13,5.3c-1.1,2.5-1.9,5.2-5.1,6c-5.7,1.5-5.9,10.8-10.1,11.7l-96.2,18.9l-5.8,0.3l-14-46.3h-8.6
		c-0.7-2.9-1.4-5.9-1.1-8.8c-7.4-8.9-12.5-18.4-17.4-26.4c-2.8-4.7-3.8-10.2-5.6-15.3c-1.4-4.1-3.3-7.6-4.5-11.5l4.9-1.7l-5.3-18.3
		c-3.5-17.6-7.4-35.5-13-53l27.5-7.4l-7.9-30.9l3.3-0.4c-0.1-0.9,1-3.2-1.5-3.2l-3.2-6.3l-0.3-11.3l-6.7,0c-0.8-7.6-2-15-4-21.9
		l11.7-0.9l-9-39.4l-7.6,0l-5.6-19.7l2-2.1c-0.9-4.1-7.1-27.6-7.9-28.7l-20.8,5.6l-8.6,0.2l-0.2,12l-16.9-1.3l-10.9,4.3l-0.2-5.4
		l-1.9-2.8l-8.4-0.2l-7.8,5.2l-24.6,3.2l-7.1-11.4l-5.9,5.4c-0.7,7.9-2.2,17.9-3.8,22.5l-4.5,3.2l-1.7-1.5l-8.3,2.5l-18.8-1.6
		l-2.5-6.1c1.3-7.7-1.9-14.5-4.9-21.1c-5,1.1-9.5,2-14.5,2.3c-2.9,1.9-5.1,3.5-7,5.6c-8.9-0.1-6.5-1.7-9.1-3
		c-0.9-0.3-2.7,0.1-3.8,0.7c-2.1,1.2-8.9,4.6-14.2,6.3c-7.7,2.1-11,4.1-13.7,6.2l-18.3-0.2l-11.4-33.8l-29.4,0.9l-2.5,5.2l-17.6-0.1
		l-0.3,2.2l-3-2.2l-9.4,18.2l-10-5.7l8.5,17.3c0.8,2-0.3,2.3-2,2l-9.6-5.8l-6.8,6.5l-3.9-3.4l-5.5-2.6l-6.5,0.2l-2.6,9.4l-24.2,15.6
		c6.6,9.1,12.8,18.3,20.6,27.1c4.4,6.9,8.8,14,9.7,25l-1.8,37.1l-5.6,10.8L134.2,485.9z'
      />
    </g>
    <g transform='translate(30.7708,-317.1503)'>
      <path
        id='second'
        d='M141.5,463.6c-0.2,28.1,13.4,50.2,38.7,61.1c4.7,2,13.4,8.2,22.9,22c4.6,6.7,5.8,15.9,13.6,24.6
		c14.8,14.5,28.1,21.1,49.6,25.7c23.8,5.1,59.4,33.7,60.2,81.4c0.3,17.5,6.5,37.5,15.5,52.7c5.2,8.7,11,18.5,17.3,30.4
		c5.2,9.5,12.1,17.4,21,24.3c8.1,7.2,15.5,14.7,21.5,23.4c5.1,5.8,13.7,20.4,21,36c3.1,6.6,13.5,30.7,40.8,38.2
		c11.6,3.2,15.5,2.1,23.1,1.4c2.5-0.2,6.9-0.4,10.5,0.1c12,1.4,23.9,5.1,35.4,13.6c15.6,13.1,27.2,32.3,26.3,56.3
		c-0.5,14.8,1.6,23.6,6.4,36.5c7.4,19.7,19.9,35.1,35.4,46.6c15.2,9.1,44.3,21.7,48.9,64.8c1.5,16.5,13.5,31.2,24.7,35.8
		c11.4,4.7,22.2,13.2,24.9,27.6'
      />
      <path
        id='third'
        d='M331.8,915.2l-4.6-24.4l-0.4-22.7l-15.7-38.7l-5.8-23.6L274.7,748l-2.5-9.9l-0.7-8.2'
      />
      <path
        id='fourth'
        d='M274.1,983l13.4-66.1l23.3,4.2l46.9-13.1l0.2-1.3l5.9-1.6l1.6,1.4l1.7-0.6l5.7,6.1l2.5,7.8
		l8.3,9.6l8.1-0.4l4.7,0.4l3.9-10.2l19.7-0.4l6-6v-3.1l-1.8-8.4l6-1.3l1,2.5l11.1-3.1l-0.5-2.5l16.4-4l5.8-8.7'
      />
      <path id='path2069' d='M567.6,674.7l4.1,12l-1.9,1.5v7.9h-2.1l6,32.4' />
      <path
        id='fifth'
        d='M625.2,807.2l-4.1-2.9c-1.1-1.9-0.6-3,0.1-4.3l2.2-3.3l0.1-7.2l-40.1-49.1l0.2-11.4l-67.1-2.8
		l-11.8,2.4l-3.6-0.2l-13.3,3.9l-19.3,4.5l-5.6,6.4l-18.4,11.4l-5.8,2.4c-19.6,11.7-24.9,13.7-21.9,35.4
		c-3.3,6.8-8.7,11.9-14.8,16.5'
      />
      <path
        id='sixth'
        d='M488.6,732.2c-26.4-10.9-52.8-20.1-79.2-33c-9.2-3-14.7-8.1-20-13.3c-19.5,1.1-17.5-23.5-26-36
		l-0.2-21.9c-3.8-5.6-6.1-10.3-9-13.3c-6.3-6.4-4.3-9.9-7.3-16.5'
      />
      <path
        id='seventh'
        d='M39.2,654H48l1.6-3.4l8.1,0.4l10.3,12.3l6,0.9l37,15l14.8,6.7l50.9,16.4l6.3,7.4l21.5,9'
      />
      <path
        id='path1901'
        d='M319.3,850.3l-25.3,8.5c-2.1-0.4-4.2,0.8-6.3,2.2l-16.8,6c-3.9,1.7-7.8,3.8-11.6,4.9
		c-5.5,2.3-10.9,6.3-16.4,9.6l-1.8-1.8l-9.2,3.6l-4.9,2l-7.4,1.6c-0.7,3-2.3,5.2-4.3,6.9c-1,3.5-4.2,5.4-7.6,7.2h-16.4
		c-3-1.8-6.7-1.5-12.8,3.8c-3.2,2.2-6.4,3.2-9.6,4.3c-8,1.3-15.1,4.7-22,9c-19.2-1.2-11.2-8.7-8.3-15h-9.4l-4.9-3.4h-8.1'
      />
      <path
        id='path1903'
        d='M168.6,909.4c-1.2,4.3-1.5,10.5,0.4,13c1.9,2.4,5.4,7.4,9.1,14.1c3.7,6.8,6.6,9.4,9.3,12.4
		c2.7,3,6.9,4.6,11.2,6.2c25.2,8.6,50.2,18.5,75.2,28.4c6.3,2.4,10.2,8.6,10.8,17.7l0.6,62.3c1,4.7,0.6,11-0.1,17.6
		c-0.2,1.9,0.5,13.4,0.5,20.9c-0.6,4.4,1.8,6.1,1.2,10.7l4.3,64.1c-7.9-0.2-11.5-1.6-11.7-0.3l-2.2,6.4'
      />
      <path
        id='path1905'
        d='M531.8,897.7l8.5-8.7c0.2-2.1,1.4-3.1,3.1-3.5l50.9-53.2l12.3-0.1c5.8-0.5,7.4-4.5,8.2-9.1
		c7.1-11.4,14.9-21.4,22.8-31c1.2-0.6,2.2-2.1,3.7,0.1l5.3,14.8l3.2-0.1c13.2-5.9,28.8-10,43.2-14.9c0.6,2.8,2.6,3.9,4.3,5.2
		l2.9-0.4l20.5-6.2c1.8-1.9,3.3-3.7,7.4-6.5l17.7-4.2c0.2-4.1-1.6-8.2-3.5-12.3l8.9-2.9l0.7-5.7c1.4-1.2,2.7-2.7,4.3-1.9
		c2,3.6,1.6,5.3,2.3,7.8c1.1,1.4,2.2,2.8,3-0.1l-1.3-22.4'
      />
      <path
        id='path1907'
        d='M877.6,970.6l-3.5-1.1l-0.5-3.2l-52.2-0.5c-1-9-1.6-17.3-0.5-27l5.1-3.5
		c-3.1-4.8-6.7-13.3-9.1-13.7c1.1-4.1,1.7-8.2,1.3-12.3l-4.3-8.3l-3.5-0.3l-12.9-23.3l2.1-0.8c-1.1-7.4-0.2-17.7,0-27
		c4.4-1.8,7.7-4.8,10.7-8L801,830c-9.6-5.7-18.1-6.5-26.2-5.9c-7.9-5.5-15.5-11.3-24.1-16.1c-4.3-1.6-8.6-7.7-12.9-11.8l-9.9-12'
      />
      <path
        id='path4713'
        d='M337.9,724.1l-6.1,3.2c-1.5,0.1-2.7,0.5-3.2,1.5c-1.8,1-3.8,1.9-6.3,2.7l-6.8,0.3l-2.3-1.7
		c-1.4,0-2.8-0.4-4,0.3l-24.7-1.1l-0.7-0.7l-12.9,0.9l-16.8,9.1l-0.4,1l-6.3,3.4c-0.8,1.7-1.9,1.5-2.9,1.5c-3.8,1.1-5.6,2.8-7.8,4.4
		c-2.5,0.7-3.2,0.1-4.7,0c-0.7-1.1-1.8-1.7-2.8-2.5c-2.6,0.3-5.1-1.9-7.7-2.9c-3.3,0-5.9,0.5-7.8,1.9c-2.6,1.7-3.7-0.3-5.7-0.3
		c-4.8,0-7.9-0.5-10.9-2.8c-1.9-1.6-2.8-0.7-3.9-0.1'
      />
      <path
        id='path4780'
        d='M550.3,1304.4c-0.8-0.2-1.4,0.1-2.7-1.1c-1.9-2-3.1-4.8-4.4-7.4c-2-0.8,0.3-2.2-1.5-3.1
		s-0.6-1.9-0.8-2.9c-0.6-2.6-1.6-4.8-2.8-6.8l-19.8-2.8c0.9-1.6,8.6-0.7,13.8-0.8c1.4,0.9,2.8,1.3,4.1,0.5
		c-0.7-3.9-4.7-7.3-6.2-11.2c-1.4,0.4-3,0.7-5,0c-1.9-0.7-3.5-0.1-5.3,0c1.1-0.8,2.1-1.6,2.1-2.6c-0.4-1.8-2.4-3.5-3.7-5.2l6.9,5.8
		c1.9-0.7,3.5-0.9,5.3-0.7c0.3,0.1-1.7-6-2.5-5.8c-2,0.4-5.2-1.7-4.5-2.3c1.4,0.1,3,0.8,4,0c-0.8-3.3-3.8-6.7-4.8-10
		c-1.2,0.1-2.9,1.5-3-0.9c0.8-0.6,1.3-1.3,0.8-2.2c-1.2-0.5-1.7-1.7-2.5-2.7c-2,1.2-3.6-1.1-7.3-1.6c1.9-0.2,3.4-0.8,4.5-1.3
		c-0.9-0.5-2.1-0.8-2-2c0.6-1.2,1.2-2,2.6-2.9c-2.4-2.9-4.1-4.4-5.6-7c-0.9,0.7-1.8,1.5-2.6,3.2l-1.2-2.9c0.7-0.2,1.6-0.2,2.2-1.2
		c0.4-2.4,0-3.3-1.1-3.6c-1.8-0.5-2-3.4,0-4.9c-1-1.2-1-2.8-1-4c-0.8-0.8-1-1.8-1.3-2.7c-1.5-0.3-2.4,0.1-2.7,1.3
		c-2.4-2.2-4.8-0.9-7.2,0c0.9-1.2,1.9-2.2,7.9-2.9c0.7-0.3-0.1-3.6-1.7-8c-1.5-0.6-2.1,1.8-2.9,3.3c0.4-1.3,1.1-3-0.1-3.7
		c-1,0.4-2.3,1.2-3.1,0.2c-0.8-1-1.7-0.5-2.6-0.3c-0.9-2.5-2.7-4.9-4.1-7.4c-0.8,0.9-2.4,0.6-3.8,0.6l-0.2-7.4
		c-3.4-6.5-6.5-12.9-10.1-19.2l5.2-3.1l-5-8.1l-1.1,0.8c-0.6-1.8-1.2-2.4-1.9-3l0.8-0.8c-0.9-0.6-1.8-2.1-2.3-3.4
		c-0.7-1.6-1.9-1.7-2.3-2.9c-0.9-2.1-1.5-2.6-2.9-4.2l-1.4,0.9c0.3-1.5,0.3-3.1-0.2-4.8c-1.3-0.9-2.1,0.1-2.6-0.3
		c-1.5-0.9-1.2-1.6-1.7-2.7l-0.5-5c-1.3-4.1-2.6-5-3.5-9c-1.3-3.6-2.1-4.6-3.2-7c-2.2-4.9-4.2-9-5.3-7.9c-0.2-3.1-1.5-4.4-2.8-6.1
		c-2.2-3.4-3.8-4.9-5.6-7.3l-5.6-7.3c-0.8,0.1-1,0-0.9,2.8c-0.7,0.1-1.3-1.2-2.1-2.7c0.1-1,0.9-1.5,1.8-1.9
		c-2.5-2.4-3.4-4.8-4.6-7.1c0.6-1.6,10.3-3.5,15.9-5.3c4.4-0.6,8.8-0.6,13.2-2.7c4.1-0.8,11.7,0.7,18.6,1.7c1.4-0.2,1.8-0.9,1.5-1.9
		l-12-19.2c1.8-1.4,3.9-2.5,8.4-2c6.6-4.5,11.6-10.8,16.9-16.8l17.1-17c6.8,5.8,17.8,11.9,19.2,15.4l10.5-1.4
		c0.6-5.6-0.1-18.1,5.4-19.6l3.1-0.4c5.6-5.4,2.6-10.4,3.2-15.8c0.5-5.1,4-7.3,8.5-8.4c0.8-3.5,2.3-5.6,4.7-5.9'
      />
      <path
        id='path5667'
        d='M442.4,1071.9l-14.7-14.1c-2.3-1.1-3.6-3-4.7-4.9c-1.8-0.7-3.7-1.5-5.7-1.7
		c0.3,6-2.7,11-6.9,15.9l-5.4,6.2c-5.4,3.5-11.7,6.6-18.3,8.8c-1.4-0.1-5-3.2-6.4-3.4c-0.4-0.1-3.9-0.1-3.9-0.1l0,1.7l-2.2,0
		c-1.3,0.8-1.1,2-1.1,3.1c-2.4-0.5-4.9-0.6-7.3-0.5c-1.1-0.4-1.9-0.7-3.8-1.5c0,0.8,0.6,1.5,0.7,2.7c-1.4,2.9-2.8,5.4-3.3,7.8
		l-3.9-2.3l-2.4,0l0-2.9l-8.4,0.4c-0.9-1.5-0.7-3.6-1-5.3c-2.3-0.7-4.8-2-7.2-3.3l-0.8,1.8c-0.4,0.6-1,1-2.7,0.2
		c-1.3-0.2-13.7-5.3-22.8-8.4l-1.7,0.8c-6.4-3.6-12.7-5.4-19.1-7.9c-0.8,0.8-1.1,2.2-1.4,3.6c-0.9-0.7-1.8-1.6-2.3-1.6'
      />
      <path
        id='path7439'
        d='M509.3,546.1l-41,0.2l-7.3,1.5c-1.1-5.7-3.8-3.2-3.5,0.9c-7.8,3.2-16.7,7.9-21.2,8.8
		c-4.5,0.9-43.1,16.7-43.1,16.7c-3-3.4-5.8-0.9-6,2.4c-4,2.2-7.7,3.8-11,4.6c-5.2,0.8-8.3-0.6-7,4.3c-3.2,2.6-7,4.2-11,5.6
		c-1.7-1.9-3.6-3.5-5.4-3c-1.9-4.9-6.7-6.9-6.3,1.9c-0.4,2.6-1.8,5.1,0,7.7c-2.7,0.8-3.6,3.5-8,4.6c-3.1,0.8-4.9,3.5-8,4.6
		c-2-0.2-4.9-0.1-6.2,0.8c-1.2,0.9-2.5,0.8-3-0.3c-6.2-13.1-8.9-1.8-8.9,1c0.1,2.8,0.9,5.4-2.8,7.8c-2.2,1.9,0.5,2.1,0.5,3.6
		c-5.4,4.4-8.9,6.1-14.6,10.7c-5.2,4.2-7.6-5.6-12.1-1.6c-1.6,1.2-2,2-0.8,4.4c0.5,4.4-1.5,5.9-3.5,7.5l-39.1,32.8
		c-6.9-0.3-8.3,3.6-8.9,8.3c-2.8,4-5.3,8-9,12.2c-3.1,2.7-5.4,5.8-6.4,9.7c-1.9,3-4.2,6-6.5,8.7c-1.6,1.9-4,3.8-5,5.8
		c-0.9,5.3-3,10.4-6.9,14.5c-4.7,5-4.8,8.2-3.4,11.4c-1.1,3.8-4.5,8-6.8,11.4l-8.8,8.1c-4.3-0.7-6.9,4-9.4,5.5
		c0,0-25.3,20.2-26.4,25.1c-4.1,19.6-6.6,30.2-9.8,45.8c-1.5,7.4,1.1,13.9,1.1,13.9s4.1,9.3,5.2,15.4c1.3,7.3,1,9.7-3.2,13.8
		c-2.9,2.9-8,6-12,5.8c-6.5-0.4-5.3,3.2-5.9,5.5l-1.8,7.3L46.7,916c-3.6,1.3-7.3,1.6-10.5,5.1h-3.3c-4.3-4-7.2-1.6-10.3-0.1l-9.2,0'
      />
      <path
        id='path9211'
        d='M36.7,920.9l45.7,29.6c-1.6,8.7,8,12.9,14.8,14.2c5.9,3.4,10.1,7.2,15.9,15
		c5.8,7.1,9.7,2,13.4,0.4l-9.3,28.5'
      />
    </g>
  </Svg>
);

export { Warsaw };
