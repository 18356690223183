import styled from 'styled-components';

export const TextContainer = styled.div`
  max-width: calc(900px + 2 * 2rem);
  margin: 0 auto;
  padding: 0 2rem;
`;

export const Section = styled.section`
  max-width: calc(510px * 2 + 4 * 10px); /* two photos + margins */
  min-height: 100vh;
  margin: calc(16vh - 48px) auto 0; /* standard- the height of sticky element */
  img {
    margin: 10px;
    height: auto;
  }
  .third {
    width: calc(100% - 2 * 10px);
    max-width: 333px;
    display: inline-block;
  }

  .half {
    width: calc(100% - 2 * 10px);
    max-width: 510px;
    display: inline-block;
  }
  .full {
    max-width: 1040px;
    width: calc(100% - 2 * 10px);
  }

  p {
    font-size: 1.6rem;
    line-height: 2.3rem;
  }
`;
