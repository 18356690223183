import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Path } from '../paths';
import Navigation from '../components/app/navigation';
import TypedTitle from './components/TypedTitle';
import { useCatch } from '../utils/useCatch';
import { Button } from '../components/common/button';
import LadnieLogoNew from '../assets/ladnieLogoNew';
import Footer from '../components/app/footer';

const Section = styled.section`
  position: relative;
  background: black;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  .toggler {
    display: none;
  }
  .video-cover {
    min-width: 100%;
    min-height: 100%;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(43, 39, 59, 0.95);
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: calc(100% - 8rem);
  }
  .logo {
    width: 400px;
  }
`;

const HomeSlider = (props: {
  articleRef: React.RefObject<HTMLDivElement>;
}): React.ReactElement => {
  //serving video
  useCatch('/assets/new/1014949114-sd.mp4');
  const backgroundVideo = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    backgroundVideo.current?.play();
  }, []);
  //serving video end
  return (
    <Section>
      <Navigation brandingColor='light' articleRef={props.articleRef} />
      <video
        ref={backgroundVideo}
        className='video-cover'
        autoPlay
        loop
        muted
        playsInline
      >
        <source src='/assets/new/1014949114-sd.mp4' />
      </video>
      <div className='overlay'>
        <div className='container'>
          <LadnieLogoNew color='light' width='210px' />
          <div>
            <TypedTitle fontSize='4.1rem' />
            <Link to={Path.Portfolio}>
              <Button
                className='contactUs'
                transparent
                style={{
                  color: 'var(--white)',
                  marginTop: '4.1rem',
                }}
              >
                Zobacz Case Studies
              </Button>
            </Link>
          </div>
          <div></div>
        </div>
        <Footer theme='homepage' />
      </div>
    </Section>
  );
};

export default HomeSlider;
