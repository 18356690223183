import React, { createRef, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import Navigation from '../../components/app/navigation';
import { Article } from '../../components/common/article';
import Footer from '../../components/app/footer';
import Interested from '../interested';
import Content from './content';
import { Path } from '../../paths';

const Emjot = (): JSX.Element => {
  const title = 'Emjot  Ładnie\u2122 | Intuicyjne Oprogramowanie';
  const description = 'Projekt Emjot zrealizowany przez Ładnie';
  const canonical = `https://ladnie.agency${Path.Emjot}`;

  useEffect(() => {
    //send GA virtual pageview
    TagManager.dataLayer({
      dataLayer: {
        event: 'VirtualPageview',
        virtualPageURL: Path.Emjot,
        virtualPageTitle: title,
        title: title,
      },
    });
  }, []);

  const ref = createRef<HTMLDivElement>();
  return (
    <Article ref={ref}>
      <Helmet>
        <title itemProp='name' lang='pl'>
          {title}
        </title>
        <link rel='canonical' href={canonical} />
        <meta name='description' content={description} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
      </Helmet>
      <Navigation brandingColor='dark' articleRef={ref} />
      <Content />
      <Interested />
      <Footer theme='light' />
    </Article>
  );
};

export default Emjot;
