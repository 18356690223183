import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

interface TypographyProps {
  fontSize?: string;
  color?: string;
  margin?: string;
  marginBottom?: string;
}

const typographyProps = css<TypographyProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "")};
  color: ${(props) => (props.color ? props.color : "")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
`;
// only @ mobile home page
const OpeningP = styled.p`
  font-family: "Fira Sans", sans-serif;
  font-weight: 200;
  font-size: 3rem;
  ${typographyProps}
`;

const PTagline = styled.span`
  display: inline-block;
  font-size: 17px;
  font-family: "Fira Sans", sans-serif;
  font-weight: 700;
  color: var(--accent);
`;

const MenuLink = styled(NavLink)`
  font-family: "Fira Sans", sans-serif;
  font-weight: 300;
  font-size: 4.6rem;
  @media screen and (min-width: 576px) {
    font-size: 6.4rem;
  }
`;

const TagFont = styled.span`
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--gray);
  ${typographyProps}
`;

const H1 = styled.h1`
  font-family: "Merriweather", sans-serif;
  font-size: 2.4rem;
  margin: 2.3rem 0;
  ${typographyProps}
`;

const H2 = styled.h2`
  font-family: "Merriweather", serif;
  font-size: 2.4rem;
  margin: 2.3rem 0;
  ${typographyProps}
`;

const H3 = styled.h3`
  font-family: "Merriweather", serif;
  font-size: 1.8rem;
  margin: 2.7rem 0;
  ${typographyProps}
`;

const H4 = styled.h4`
  font-family: "Fira Sans", sans-serif;
  font-size: 2.1rem;
  font-weight: 500;
  color: var(--white);

  ${typographyProps}
`;
const H5 = styled.h5`
  font-family: "Merriweather", serif;
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--gray);
  ${typographyProps}
`;
const H6 = styled.h6`
  font-family: "Merriweather", serif;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--gray);
  ${typographyProps}
`;

const Pnormal = styled.p`
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @media screen and (min-width: 576px) {
    font-size: 2.1rem;
    line-height: 2.7rem;
  }
  margin: 2.3rem 0;
  color: var(--black);
  ${typographyProps}
`;
const Plight = styled.p`
  font-family: "Fira Sans", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;

  line-height: 1.8rem;
  margin: 2.3rem 0;
  color: var(--gray);
  ${typographyProps}
`;
const Strong = styled.strong`
  font-weight: 700;
  ${typographyProps}
`;

export {
  OpeningP,
  PTagline,
  MenuLink,
  TagFont,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Pnormal,
  Plight,
  Strong,
};
