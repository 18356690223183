import React from 'react';
import styled from 'styled-components';

import { Path } from '../paths';
import 'lazysizes';
import { Tile } from '../components/common/tile';
const Section = styled.section`
  max-width: 1920px;
  margin: 10rem auto 0;
  min-height: 50vh;
`;

const Column = styled.div`
  display: inline-block;
  width: 100%;
  /* max-width: calc(960px); */
  @media screen and (min-width: 960px) {
    width: 50%;
  }

  vertical-align: top;
`;

const Gallery = () => {
  return (
    <Section>
      <Column>
        <Tile
          link={Path.Emjot}
          text='Branding'
          title='MJ nieruchomości'
          image={
            <img
              data-sizes='auto'
              data-src='/assets/images/gallery/emjot2x.jpg'
              data-srcset='
          /assets/images/gallery/emjot2x.jpg 1020w,
          /assets/images/gallery/emjot3x.jpg 1530w
          '
              className='lazyload'
              alt='Emjot'
            />
          }
        />
        <Tile
          link={Path.Simba}
          text='Materiały reklamowe'
          title='Simba'
          image={
            <img
              data-sizes='auto'
              data-src='/assets/images/gallery/simba2x.jpg'
              data-srcset='
          /assets/images/gallery/simba2x.jpg 1020w,
          /assets/images/gallery/simba3x.jpg 1530w
          '
              className='lazyload'
              alt='Simba'
            />
          }
        />
      </Column>
      <Column>
        <Tile
          link={Path.Symposio}
          text='Branding'
          title='Symposio'
          image={
            <img
              data-sizes='auto'
              data-src='/assets/images/gallery/symposio2x.jpg'
              data-srcset='
      
          /assets/images/gallery/symposio2x.jpg 1020w,
          /assets/images/gallery/symposio3x.jpg 1530w
          '
              className='lazyload'
              alt='Symposio'
            />
          }
        />
      </Column>
    </Section>
  );
};

export default Gallery;
