import React, { createRef, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Path } from '../paths';
import { Helmet } from 'react-helmet';
import Navigation from '../components/app/navigation';
import { Article } from '../components/common/article';
import OfferHeader from './header';
import ServiceScope from './serviceScope';
import Interested from './interested';
import Footer from '../components/app/footer';
import Partners from './Partners';

const Offer = (): JSX.Element => {
  const ref = createRef<HTMLDivElement>();

  const title = 'Oferta Ładnie\u2122 | Intuicyjne Oprogramowanie';
  const description =
    'Design, www development, marketing. Aplikacje, projekty webowe i druk od A do Z.';
  const canonical = `https://ladnie.agency${Path.Offer}`;

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'VirtualPageview',
        virtualPageURL: Path.Offer,
        virtualPageTitle: title,
        title: title,
      },
    });
  }, []);

  return (
    <Article ref={ref}>
      <Helmet>
        <title itemProp='name' lang='pl'>
          {title}
        </title>
        <link rel='canonical' href={canonical} />
        <meta name='description' content={description} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
      </Helmet>
      <Navigation brandingColor='dark' articleRef={ref} />
      <OfferHeader />
      <ServiceScope />
      <Partners />
      <Interested />
      <Footer theme='light' />
    </Article>
  );
};

export default Offer;
