import React from 'react';

const Phone = () => (
  <svg viewBox='0 0 81.7 81.7' width='50' height='50'>
    <path
      d='M72.4,51.1l-0.9,0l-14.5,0c-2.7,0-5.5,0-7.5,2c-1.2,1.1-1.7,2.7-1.8,4.6C35.4,56.1,25.6,46.4,24.1,34
		c1.9,0,3.5-0.6,4.6-1.8c2-2,2-4.8,2-7.5l0-0.9l0-14.5c0-2.7,0-5.5-2-7.5C27.5,0.6,25.9,0,23.8,0H10.2C4.6,0,0,4.6,0,10.2v3.4v10.2
		c0,31.9,26,57.9,57.9,57.9h10.2h3.4c5.6,0,10.2-4.6,10.2-10.2V57.9c0-2-0.6-3.7-1.8-4.9C77.9,51,75.1,51.1,72.4,51.1z M74.9,71.5
		c0,1.9-1.5,3.4-3.4,3.4h-3.4H57.9C29.7,74.9,6.8,52,6.8,23.8V13.6v-3.4c0-1.9,1.5-3.4,3.4-3.4h13.6c0.1,0.6,0.1,1.7,0.1,2.5l0,0.9
		l0,14.6c0,0.8,0,1.8-0.1,2.5h-3.3H17v3.4c0,18.8,15.3,34.1,34.1,34.1h3.4v-3.4V58c0.6-0.1,1.7-0.1,2.5-0.1l0.9,0l14.6,0
		c0.8,0,1.8,0,2.5,0.1V71.5z'
    />
    <path d='M74.9,39.2h6.8C81.7,17.6,64.2,0,42.6,0v6.8C60.4,6.8,74.9,21.3,74.9,39.2z' />
    <path d='M57.9,39.2h6.8C64.7,27,54.8,17,42.6,17v6.8C51,23.8,57.9,30.7,57.9,39.2z' />
  </svg>
);

const Envelope = () => (
  <svg viewBox='0 0 81.7 61.3' width='50' height='50'>
    <path
      d='M75.9,1L75.9,1L75.9,1c-1.4-0.7-2.8-1-4.4-1H10.2C8.8,0,7.5,0.3,6.3,0.8L6.2,0.7L5.9,1C2.4,2.7,0,6.2,0,10.2
	v40.9c0,5.6,4.6,10.2,10.2,10.2h61.3c5.6,0,10.2-4.6,10.2-10.2V10.2C81.7,6.2,79.3,2.7,75.9,1z M69.9,6.8L43.7,32.3
	c-1.4,1.4-3.8,1.4-5.2,0L12.5,6.8H69.9z M6.8,10.8L25,28.6L6.8,46.7V10.8z M71.5,54.5H10.2c-0.5,0-0.9-0.1-1.3-0.3l21-20.9l3.9,3.8
	c2,2,4.7,3,7.4,3c2.7,0,5.3-1,7.4-3l3.7-3.6l21,20.5C72.7,54.3,72.2,54.5,71.5,54.5z M74.9,46.1L57.1,28.7l17.8-17.4V46.1z'
    />
  </svg>
);

export { Phone, Envelope };
