import React, { ReactElement, useContext } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { FirstLoadContext } from '../components/app/firstLoad';

interface Props {
  color: 'dark' | 'light' | 'accent';
  firstLoad?: boolean;
  width?: string;
}

//animation
const logoEnter = (color: string) => keyframes`
from {
fill: transparent;
} to {
	fill: ${`var(--${
    color === 'dark' ? 'black' : color === 'light' ? 'white' : 'accent'
  })`};;
}
`;
//animation
const strokeDraw = (length: number) => keyframes`
from {
	stroke-dashoffset: ${length};
}
to{
stroke-dashoffset: 0;
}
`;
//helper for ternary operator
const firstAnimation = css<Props>`
  animation: ${(props) => logoEnter(props.color)} 1000ms 300ms linear forwards;
  #lad {
    stroke-dasharray: 4274.18;
    animation: ${strokeDraw(4274.18)} 0ms linear forwards;
  }
  #nie {
    stroke-dasharray: 3141.87;
    animation: ${strokeDraw(3141.87)} 0ms linear forwards;
  }
  #dot {
    stroke-dasharray: 199.55;
    animation: ${strokeDraw(199.55)} 0ms linear forwards;
  }
`;

const Svg = styled.svg<Props>`
  width: ${(props) => props.width};

  stroke: ${(props) =>
    `var(--${
      props.color === 'dark'
        ? 'black'
        : props.color === 'light'
        ? 'white'
        : 'accent'
    })`};
  stroke-width: 0;
  /* if first load load animation, otherwise fill with color */
  ${(props) =>
    props.firstLoad === true
      ? firstAnimation
      : `
		fill: ${`var(--${
      props.color === 'dark'
        ? 'black'
        : props.color === 'light'
        ? 'white'
        : 'accent'
    })`};
		`}
`;

export default function LadnieLogoNew({
  color,
  width = '130px',
}: Props): ReactElement {
  const firstLoad = useContext(FirstLoadContext);

  return (
    <Svg
      viewBox='0 0 73 27.09'
      color={color}
      width={width}
      fill='none'
      firstLoad={firstLoad.isFirstLoad}
      onAnimationEnd={() => {
        firstLoad.setFirstLoad(false);
      }}
    >
      <title>Artboard 2</title>
      <g
        id='Layer_2'
        data-name='Layer 2'
        onAnimationEnd={(event) => event.stopPropagation()}
      >
        <g id='Group_144' data-name='Group 144'>
          <path
            id='lad'
            data-name='Path 2746'
            d='M8.07,22a19.5,19.5,0,0,1,2.46-5.07,22.18,22.18,0,0,1,5.92.34c1.2-2-1.37-2.63-4.51-2.48.24-.36.5-.72.76-1.08C21.1,13.39,28.6.91,24.82.2c-2.63-.49-9.5,5.5-14.09,11.37C6.29,9.7,13.05.87,17.82,1.76c.08-.45-.74-1.4-2.15-1.67C9.82-1,4.62,8.23,8,12.07l.1.1a4.36,4.36,0,0,0,1.34.95,6.9,6.9,0,0,0,1.72.49c-.1.52-1.15.38-2,.15-.36.52-.68,1-1,1.52C5.28,15.91,3,17,3.76,18.12c.7.13,2.49-.65,5.27-1.05a6.42,6.42,0,0,1-2.39,1.27c-.64,1.88-.47,3.28,1,3.76h0c.29.12.48.18.48.18-.13.37-.74.29-1.39.08C2.52,23.57-.7,25.43.13,27.09c6.66-3.61,18.67-4.47,20.55-1.72C22.86,20.44,14.76,20.34,8.07,22ZM24.4,1.8C23.19,4.11,19.59,11,14.05,11.87,17.76,7,22.18,2.41,24.4,1.8Z'
          />
          <path
            id='nie'
            data-name='Path 2747'
            d='M72.61,13s-3.69,5.43-7.32,4.75c-1.49-.28-1.32-2-.44-3.77,1.61,5.77,10.91-5.12,5.05-6.22-3.51-.65-6.78,2.56-8.14,5.88-.7,1.08-2.56,3.75-3.86,3.51-1.69-.32,3.71-7,3.71-7s-.44-1.28-1.47-1.47-3.47,3.13-4.75,6.16C54.6,15.9,53.54,17.1,52.76,17c-1.58-.3,3.12-6.47,3.16-6.71a1.62,1.62,0,0,0-1.34-1.85h0c-1.44-.27-4.63,3.12-7.17,6.14,2.07-3,4-5.77,1.34-6.27-3.6-.68-9.15,9.17-10.61,8.9C39.4,10.45,50,4.84,43.89,3.69c0,0-2.56,3.24-4.89,6.85a1.51,1.51,0,0,0-1-1.88,2.14,2.14,0,0,0-.35-.06c-3-.55-6.46,3.45-7.79,6.87-.65.7-2,2.06-2.63,1.94s.8-1.86.92-2.45-1.52-1.61-2.05-1.89c3.3,1,7.42-3.87,2.73-4.75C22,7,14.28,19.32,18.65,20.14c2.54.47,5.31-3.06,6.72-5.17A4.74,4.74,0,0,1,24.3,17.5c-.24,1.31-.1,2.45,1,2.65,2.12.4,4.4-2.63,5.28-4a4.65,4.65,0,0,1-1.12,2.62A1.65,1.65,0,0,0,30.76,20c1.9.35,4.32-2.56,5.75-4.6a4.5,4.5,0,0,1-1.21,2.74c-.17,1.09.09,1.84,1,2,3.9.73,8.3-10.38,12-10.28-2.19,2.33-8.17,9.19-3.85,10,0-.08,0-.15.05-.22.42-1.24,5.61-7,8.48-8.76-1.73,2.58-5.24,8.33-2.58,8.83,1.53.28,3.15-1.1,4.44-2.64l.89-.94a5.2,5.2,0,0,1-1.1,2.32,1.32,1.32,0,0,0,1.11,1.1C58,20,60.64,16.92,62,15a5,5,0,0,1-.88,2.31,3,3,0,0,0,2.55,2.74C69.07,21.08,74.5,13.33,72.61,13ZM69.85,8.56s0,0,0,0S69.77,8.54,69.85,8.56Zm0,.38c-.33,1.29-2.42,5.35-5,5,1.15-2.33,3.45-4.86,5-5Zm-48.7,8.27c-1.39-.26,4-7.74,6.44-7.28.94.18-1.43,2.8-1.67,3.06h0l0,0h0s-.06.12-.11.24c-.76.87-3.66,4.15-4.64,4Zm11.26.2c-1.16-.21,2.64-7.65,5.25-7.16.45.08.64.64.72,1.27l-.22.35c-1.77,2.14-4.87,5.7-5.75,5.54Z'
          />
          <path
            id='dot'
            data-name='Path 2748'
            d='M62.19,8.23c.44-2.35,3.92-3.41,3.92-3.41C65.64-1.77,58.61,7.56,62.19,8.23Z'
          />
        </g>
      </g>
    </Svg>
  );
}
