import React, { createRef } from 'react';
import NoMatchHeader from './noMatchHeader';
import Navigation from '../components/app/navigation';
import { Article } from '../components/common/article';

const NoMatch = (): JSX.Element => {
  const ref = createRef<HTMLDivElement>();

  return (
    <Article ref={ref}>
      <Navigation brandingColor='dark' articleRef={ref} />
      <NoMatchHeader />
    </Article>
  );
};

export default NoMatch;
