import styled from 'styled-components';

const Button = styled.button<{ theme?: 'light'; transparent?: any }>`
  display: inline-block;
  padding: 1.3rem 3.6rem;
  border: 2px solid var(--accent);
  border-radius: 3rem;
  text-transform: uppercase;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
  background: ${(props) =>
    props.theme === 'light' ? 'var(--navy)' : 'var(--white)'};
  background: ${(props) => (props.transparent ? 'transparent' : '')};
  color: ${(props) => (props.theme === 'light' ? 'var(--white)' : '')};
  cursor: pointer;

  &:hover {
    background: var(--accent);
  }
`;
export { Button };
