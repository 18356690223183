import styled from 'styled-components';

const Article = styled.article<{ background?: string }>`
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  background: ${(props) => (props.background ? props.background : '#ffffff')};
  text-align: center;
`;

export { Article };
