import React from "react";
import styled from "styled-components";
import { Pnormal, H1, H6, Strong } from "../../components/common/typography";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import { Section, TextContainer } from "../../components/common/singleProject";
import { Arrow } from "../../components/common/arrowCircle";
import { Path } from "../../paths";

const EmjotSection = styled(Section)`
  margin-bottom: 5rem;
`;

const Content = () => {
  return (
    <EmjotSection>
      <Arrow link={Path.Portfolio} />
      <TextContainer>
        <H1 margin="0 0 6px">Emjot Nieruchomości</H1>
        <H6 margin="6px auto 30px">Identyfikacja wizualna</H6>
        <Pnormal>
          Dla firmy <Strong>EMJOT Nieruchomości</Strong> zrealizowaliśmy logotyp
          wraz z księgą znaku oraz materiały reklamowe. <Strong>EMJOT</Strong>{" "}
          pomaga swoim klientom szybciej i bezpieczniej sprzedać, wynająć bądź
          kupić nieruchomość.
        </Pnormal>
        <img
          width="160px"
          src="/assets/images/emjot/emjotLogoRed.svg"
          alt="Emjot logo"
          style={{ margin: "calc(70px - 2.3rem) auto" }}
          height="180px"
        />
        <Pnormal>
          Sygnet przedstawia dwie budowle (w zamyśle nieruchomości) w rzucie
          izometrycznym, dzięki czemu wizualnie uzyskujemy figury zbliżone do
          pochodzenia namingu - liter “M” oraz “J”.
        </Pnormal>
      </TextContainer>
      <img
        data-sizes="auto"
        data-src="/assets/images/emjot/envelope.jpg"
        data-srcset="
        /assets/images/emjot/envelope.jpg 510w,
        /assets/images/emjot/envelope@2x.jpg 1020w,
        /assets/images/emjot/envelope@3x.jpg 1530w
    "
        className="lazyload half"
        alt=""
        height="322px"
      />
      <img
        data-sizes="auto"
        data-src="/assets/images/emjot/cards.jpg"
        data-srcset="
        /assets/images/emjot/cards.jpg 510w,
        /assets/images/emjot/cards@2x.jpg 1020w,
        /assets/images/emjot/cards@3x.jpg 1530w
    "
        className="lazyload half"
        alt=""
        height="322px"
      />
      <TextContainer>
        <Pnormal>
          Dodatkowym motywem zamkniętym w sygnecie jest skojarzenie z kształtem
          serca. Całość wzmacnia czerwony kolor wybrany, jako barwa przewodnia
          identyfikacji firmy.
        </Pnormal>
        <Pnormal>
          Porządane skojarzenia: <br />
          <Strong>
            Profesjonalna firma, skuteczni, prężnie działający fachowcy z pasją
            innowacyjność, nieruchomości, przyjazna obsługa, prestiż
          </Strong>
        </Pnormal>
      </TextContainer>
      <img
        data-sizes="auto"
        data-src="/assets/images/emjot/letterhead.jpg"
        data-srcset="
        /assets/images/emjot/letterhead.jpg 510w,
        /assets/images/emjot/letterhead@2x.jpg 1020w,
        /assets/images/emjot/letterhead@3x.jpg 1530w
    "
        className="lazyload half"
        alt=""
        height="419px"
      />
      <img
        src="/assets/images/emjot/logo2-1.svg"
        alt=""
        className="half"
        height="419px"
      />
      {/* full width image */}
      <img
        data-sizes="auto"
        data-src="/assets/images/emjot/set.jpg"
        data-srcset="
        /assets/images/emjot/set.jpg 1040w,
        /assets/images/emjot/set@2x.jpg 2040w,
        /assets/images/emjot/set@3x.jpg 3120w
    "
        className="lazyload full"
        alt=""
        height="657px"
      />
      <TextContainer>
        <Pnormal>
          Realizujemy kompleksowe identyfikacje wizualne oparte o przemyślaną
          strategię oraz wizję. <br />
          Chętnie zaprojektujemy Twoją markę.
        </Pnormal>
      </TextContainer>
    </EmjotSection>
  );
};

export default Content;
