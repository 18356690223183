import React from 'react';
import styled from 'styled-components';

export interface ISvg {
  bckgrnd: string;
  line: string;
}

const Svg = styled.svg<ISvg>`
  width: 100%;
  max-width: 333px;
  .st0 {
    fill: ${(props) => props.bckgrnd};
  }
  .st1 {
    fill: ${(props) => props.line};
  }
`;

export const SymposioSygnet = (props: ISvg) => {
  return (
    <Svg
      x='0px'
      y='0px'
      viewBox='0 0 333 333'
      bckgrnd={props.bckgrnd}
      line={props.line}
    >
      <rect className='st0' width='333' height='333' />
      <g>
        <path
          className='st1'
          d='M210.6,192.5c-18.7,0-35.1,5.1-44.1,13.6c-9-8.5-25.4-13.6-44.1-13.6c-24.2,0-44.1,8.6-49.7,21.4
		c-1,1.3-1.5,2.8-1.5,4.4v1.1l16.4,0.1v-1.2c0-2.4,0.7-3,6.8-6c7.3-3.6,17.5-5.6,28-5.6c22,0,35.5,9.8,36.9,11.4l0.1,0.2
		c0.3,0.6,3.1,5.5,7,5.5s6.7-4.9,7-5.5l0.1-0.2c1.4-1.6,15-11.4,36.9-11.4c10.5,0,20.8,2,28,5.6c6.1,3,6.8,3.6,6.8,6v1.2l16.4-0.1
		v-1.1c0-1.6-0.5-3.2-1.5-4.4C254.7,201.1,234.8,192.5,210.6,192.5z'
        />
        <path
          className='st1'
          d='M261.8,134.2L261.8,134.2c-0.1-1.4-0.6-2.7-1.5-3.8c-5.5-12.8-25.5-21.4-49.7-21.4c-18.7,0-35.1,5.1-44.1,13.6
		c-3.3-3.1-7.6-5.8-12.8-8c-1.7-0.7-3.5-1.4-5.4-2c-0.4-0.1-0.9-0.3-1.4-0.4c-1.2-0.3-2.3-0.6-3.5-0.9c-2.3-0.5-4.6-1-7-1.3
		c-1.1-0.2-2.1-0.3-3.2-0.4c-3.5-0.4-7.1-0.6-10.7-0.6c-24.2,0-44.1,8.6-49.7,21.4c-1,1.3-1.5,2.8-1.5,4.4v56.3
		c0,0.8,0.1,1.6,0.4,2.4l0.6,1.8l1.3-1.4c3.6-3.8,7.4-7.7,11.6-11.1l0.4-0.3v-44.8c0-2.8,3.4-6.1,8.9-8.8c4-2,9-3.5,14.6-4.5
		c4.3-0.8,8.9-1.1,13.5-1.1c4.1,0,8.1,0.3,12,0.9c2.4,0.4,4.7,0.8,6.9,1.4c1,0.3,2.1,0.6,3.1,0.9c5.9,1.9,10.7,4.7,13.2,7.5
		c0.2,0.2,0.3,0.4,0.5,0.6c0.6,0.8,1,1.5,1.2,2.2c0.1,0.3,0.1,0.6,0.1,0.9v43.8c0,0.2,0,0.5,0,0.8c0,4,3.2,7.2,7.2,7.2
		s7.2-3.2,7.2-7.2c0-0.3,0-0.5,0-0.8v-43.7l0-0.1c0-3.9,7.4-9.6,19.6-12.5l0.1,0c0.1,0,0.2-0.1,0.3-0.1l0.2-0.1
		c4.6-1,9.5-1.6,14.6-1.7c0.7,0,1.3,0,2,0h0c0.7,0,1.3,0,2,0c5,0.1,10,0.7,14.6,1.7l0.2,0.1c0.1,0,0.2,0.1,0.4,0.1l0,0
		c12.3,2.9,19.6,8.6,19.6,12.5v44.8l0.4,0.3c4.2,3.3,8,7.3,11.6,11.1l1.3,1.4l0.6-1.8c0.3-0.8,0.4-1.6,0.4-2.4v-56.3
		C261.8,134.6,261.8,134.4,261.8,134.2z'
        />
      </g>
    </Svg>
  );
};
