import React from 'react';
import styled from 'styled-components';
import { Pnormal, H1, Strong } from '../components/common/typography';

const Section = styled.section`
  max-width: 640px;
  margin: 16vh auto 0;
  padding: 0 2rem;
`;

const OfferHeader = (): JSX.Element => {
  return (
    <Section>
      <H1>Co robimy?</H1>
      <Pnormal>
        <Strong>Ładnie</Strong> to polska firma zajmująca się kompleksową
        obsługą digitalową. Projektujemy i&nbsp;wdrażamy strony www, aplikacje
        oraz oprogramowanie.
      </Pnormal>
      <Pnormal>
        Budujemy strategie, analizujemy i&nbsp;testujemy ruch pod kątem wsparcia
        sprzedaży. Dbamy o każdy detal, mając na&nbsp;uwadze funkcjonalność
        i&nbsp;intuicyjność dostarczanych przez nas produktów.
      </Pnormal>
    </Section>
  );
};

export default OfferHeader;
