import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { H3, Plight } from "../components/common/typography";
import { Button } from "../components/common/button";
import { Path } from "../paths";
const Section = styled.section`
  max-width: 700px;
  margin: 5rem auto;
  padding: 0 2rem;
`;

const OfferHeader = (): JSX.Element => {
  return (
    <div style={{ background: "var(--lightGray)", overflow: "hidden" }}>
      <Section>
        <H3 margin="0 auto">Skontaktuj się z nami</H3>
        <Plight margin="2rem auto 2.3rem">
          Podobają Ci się nasze realizacje?
          <br />
          Napisz do nas, chętnie zmierzymy się z kolejnym wyzwaniem.
        </Plight>
        <Link to={Path.Contact}>
          <Button className="contactUs">Przejdź do kontaktu</Button>
        </Link>
      </Section>
    </div>
  );
};

export default OfferHeader;
