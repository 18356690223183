import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Path } from '../../paths';

const ArrowStyle = styled.div`
  display: none;
  @media screen and (min-width: 992px) {
    visibility: hidden;
    display: block;
    a {
      pointer-events: none;
    }
  }

  @media screen and (min-width: 1200px) {
    visibility: visible;
    a {
      pointer-events: all;
    }
  }

  width: 48px;
  height: 48px;
  margin: 0 0 0 30px;
  border: 2px solid var(--accent);
  border-radius: 50%;
  position: sticky;
  top: 16vh;
  background: white;
  &:hover {
    background: var(--accent);
    svg {
      fill: var(--white);
    }
  }
  svg {
    transform: rotate(0deg);
    fill: var(--gray);
    margin: calc((48px - 19px) / 2);
  }
`;

export const Arrow = (props: { link: Path }) => {
  return (
    <ArrowStyle>
      <Link to={props.link} title='powrót' className='link'>
        <svg viewBox='0 0 447.24 447.24'>
          <path d='M420.36,192.23a32,32,0,0,0-5.53-.41H99.31l6.87-3.2a63.87,63.87,0,0,0,18.08-12.8l88.48-88.48a33.1,33.1,0,0,0,4.64-42.4,32,32,0,0,0-48-4.16l-160,160a32,32,0,0,0,0,45.25l0,0,160,160a32,32,0,0,0,45.26-.06,32.59,32.59,0,0,0,2.74-3.14,33.1,33.1,0,0,0-4.64-42.4l-88.32-88.64a63.88,63.88,0,0,0-16-11.68l-9.6-4.32H413.07a33.13,33.13,0,0,0,33.76-26.88A32,32,0,0,0,420.36,192.23Z' />
        </svg>
      </Link>
    </ArrowStyle>
  );
};
