import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Path } from '../../paths';
import styled from 'styled-components';
import Menu from '../app/menu';
import { PTagline } from '../common/typography';
import LadnieLogoNew from '../../assets/ladnieLogoNew';

const NavContainer = styled.div`
  position: fixed;
  width: 100%;
  max-width: 1920px;
  line-height: 8rem;

  left: 50%;
  transform: translateX(-50%);
  z-index: 50;
  text-align: left;

  & > div > span {
    display: inline-block;
    margin: 0 0 0 4rem;
    transform: translateY(6px);
    line-height: 1;
  }

  .toggler {
    display: none;
    @media (min-width: 992px) {
      display: inline-block;
      opacity: 1;
      transition: opacity 400ms;
      &.hidden {
        opacity: 0;
      }
    }
  }
`;

const Hamburger = styled.div<{ stripesColor: string[]; bckgrnd: boolean }>`
  display: inline-block;
  width: 50px;
  height: 50px;
  vertical-align: middle;
  margin: 0 8rem 0 9%;
  padding: 10px 6px 10px;
  cursor: pointer;
  transition: background 1s;
  border-radius: 50%;
  background-color: ${(props) => props.stripesColor[1]};
  background: ${(props) => (props.bckgrnd ? 'transparent' : '')};
  .stripe {
    width: 32px;
    transition: 1s all;
    height: 5px;
    background: ${(props) => props.stripesColor[0]};
    border-radius: 3px;
    margin: 3.5px;
  }
`;

const Navigation = (props: {
  brandingColor: 'dark' | 'light';
  articleRef?: any;
}): JSX.Element => {
  const [isTop, setTop] = useState(true);
  const [isMenu, setMenu] = useState(false);

  useEffect(() => {
    const article = props.articleRef.current;
    const getYpos = () => {
      if (article.scrollTop) {
        setTop(false);
      } else {
        setTop(true);
      }
    };
    article.addEventListener('scroll', getYpos);
    return () => {
      article.removeEventListener('scroll', getYpos);
    };
  }, [props.articleRef]);

  return (
    <div>
      {isMenu ? <Menu setMenu={setMenu} /> : ''}
      <NavContainer>
        <Hamburger
          onClick={() => setMenu(true)}
          stripesColor={
            props.brandingColor === 'dark'
              ? ['var(--offerIcons)', 'var(--white)']
              : ['var(--white)', 'var(--navy)']
          }
          bckgrnd={isTop}
        >
          <div className='stripe' id='one' />
          <div className='stripe' id='two' />
          <div className='stripe' id='three' />
        </Hamburger>
        <div className={`toggler ${isTop ? '' : 'hidden'}`}>
          <Link to={Path.Home}>
            {/* <Logo color={props.brandingColor} /> */}
            <LadnieLogoNew color={props.brandingColor} />
          </Link>
          <PTagline>Intuicyjne oprogramowanie</PTagline>
        </div>
      </NavContainer>
    </div>
  );
};

export default Navigation;
