import { useEffect, useState } from 'react';

// safari needs it for now
const mediaQueryAsEventTarget = (mediaQuery: Partial<MediaQueryList>) => {
  return mediaQuery.addEventListener ? true : false;
};

export function useResponsiveSwitcher(border: string) {
  const initialState = window.matchMedia(`(max-width:${border})`).matches;
  const [isBelow, setBelow] = useState<boolean>(initialState);
  useEffect(() => {
    const mediaQuery: MediaQueryList = window.matchMedia(
      `(max-width:${border})`
    );
    const listener = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setBelow(true);
      } else {
        setBelow(false);
      }
    };
    if (mediaQueryAsEventTarget(mediaQuery)) {
      mediaQuery.addEventListener('change', listener);
    } else {
      mediaQuery.addListener(listener);
    }
    return () => {
      if (mediaQueryAsEventTarget(mediaQuery)) {
        mediaQuery.removeEventListener('change', listener);
      } else {
        mediaQuery.removeListener(listener);
      }
    };
  }, [border]);
  return isBelow;
}
