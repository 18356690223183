import React, { ReactElement } from 'react';

/* <PartnerLogo 
  srcSet = ['Intenson.png', 'Intenson@2x.png',  'Intenson@3x.png']
  width =  intensonWidth PX 
  alt = ''
  /> */

const basePath = '/assets/new/';

interface Props {
  sourceFiles: string[];
  width: number;
  alt: string;
}

export default function PartnerLogo(props: Props): ReactElement {
  const srcSet: string = props.sourceFiles
    .map((ele, ind) => `${basePath}${ele} ${ind + 1}x`)
    .join(', ');

  return (
    <img
      srcSet={srcSet}
      src={basePath + props.sourceFiles[0]}
      width={props.width}
      alt={props.alt}
    />
  );
}
