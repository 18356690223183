import React, { createRef, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Path } from '../paths';
import { Helmet } from 'react-helmet';
import { Article } from '../components/common/article';
import { useResponsiveSwitcher } from '../utils/useWidth';
import MobileHome from './homeHeaderMobile';
import HomeSliderDesktop from './homeHeaderDesktop';

const Home = (): JSX.Element => {
  const ref = createRef<HTMLDivElement>();
  const title = 'Ładnie\u2122 | Intuicyjne Oprogramowanie';
  const description =
    'Design, www development, marketing. Aplikacje, projekty webowe i druk od A do Z.';
  const canonical = `https://ladnie.agency${Path.Home}`;

  const isMobile = useResponsiveSwitcher('600px');
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'VirtualPageview',
        virtualPageURL: Path.Home,
        virtualPageTitle: title,
        title: title,
      },
    });
  }, []);

  const Home = isMobile ? MobileHome : HomeSliderDesktop;

  return (
    <Article ref={ref}>
      <Helmet>
        <title itemProp='name' lang='pl'>
          {title}
        </title>
        <link rel='canonical' href={canonical} />
        <meta name='description' content={description} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
      </Helmet>
      <Home articleRef={ref} />
    </Article>
  );
};

export default Home;
