import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Path } from '../../paths';
import { MenuLink } from '../common/typography';
import { Max1920 } from '../common/max1920slider';

const MadNav = keyframes`from {opacity: 0} to {opacity: 1}`;
//krzysztof dlaczego nie moge uzyc tej samej backwards
// seems like below is the option https://css-tricks.com/restart-css-animation/
const MadNavClose = keyframes`from {opacity: 1} to {opacity: 0}`;

const MenuContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: var(--white);
  overflow-y: auto;
  text-align: left;
  &.opening {
    animation: ${MadNav} 400ms 1 forwards;
  }
  &.closing {
    animation: ${MadNavClose} 400ms 1 forwards;
  }
  a {
    color: var(--black);
  }
  a.selected {
    color: var(--accent);
  }
  a:hover {
    color: var(--accent);
  }
`;

const Nav = styled.nav`
  margin: 6rem 1rem 0rem 9%;
  @media (min-width: 768px) {
    margin-left: calc(9% + 100px);
  }
`;

const HambOne = keyframes` to { transform: rotate(45deg) translate(6px, 6px) }`;
const HambTwo = keyframes` to {opacity: 0;}`;
const HambThree = keyframes` to {transform: rotate(-45deg) translate(6px, -6px)}`;

const HambOneClose = keyframes` 
from { transform: rotate(45deg) translate(6px, 6px) }
to { transform: rotate(0) translate(0, 0) }
`;
const HambTwoClose = keyframes` 
from {opacity: 0;}
to {opacity: 1;}`;

const HambThreeClose = keyframes`
from  {transform: rotate(-45deg) translate(6px, -6px)}
to {transform: rotate(0) translate(0, 0)}
`;

const Closer = styled.div`
  display: inline-block;
  width: 39px;
  vertical-align: middle;
  margin: 0 9%;
  cursor: pointer;
  .stripe {
    transform-origin: center center;
    height: 5px;
    background: var(--navy);
    border-radius: 3px;
    margin: 3.5px;
    &.opening {
      &#one {
        animation: ${HambOne} 400ms forwards;
      }
      &#two {
        animation: ${HambTwo} 400ms forwards;
      }
      &#three {
        animation: ${HambThree} 400ms forwards;
      }
    }
    &.closing {
      &#one {
        animation: ${HambOneClose} 400ms forwards;
      }
      &#two {
        animation: ${HambTwoClose} 400ms forwards;
      }
      &#three {
        animation: ${HambThreeClose} 400ms forwards;
      }
    }
  }
`;

const Menu = (props: { setMenu: Function }) => {
  const [closing, setClosing] = useState(false);

  return (
    <MenuContainer
      className={closing ? 'closing' : 'opening'}
      onAnimationEnd={() => {
        if (closing) props.setMenu();
      }}
    >
      <Max1920>
        <div style={{ lineHeight: '8rem' }}>
          <Closer
            onClick={() => {
              setClosing(true);
            }}
          >
            <div
              className={`stripe ${closing ? 'closing' : 'opening'}`}
              id='one'
            />
            <div
              className={`stripe ${closing ? 'closing' : 'opening'}`}
              id='two'
            />
            <div
              className={`stripe ${closing ? 'closing' : 'opening'}`}
              id='three'
            />
          </Closer>
        </div>
        <Nav>
          <ul>
            <li>
              <MenuLink
                exact
                to={Path.Home}
                activeClassName='selected'
                onClick={() => setClosing(true)}
              >
                Start
              </MenuLink>
            </li>
            <li>
              <MenuLink to={Path.Offer} activeClassName='selected'>
                Oferta
              </MenuLink>
            </li>
            <li>
              <MenuLink to={Path.Portfolio} activeClassName='selected'>
                Case Study
              </MenuLink>
            </li>
            <li>
              <MenuLink to={Path.Contact} activeClassName='selected'>
                Kontakt
              </MenuLink>
            </li>
          </ul>
        </Nav>
      </Max1920>
    </MenuContainer>
  );
};

export default Menu;
